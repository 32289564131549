<template>
    <v-col 
        v-if="currReg" 
        align="center"
        class="info-col"
    >
        <v-avatar
            color="surface-variant"
            size="100"
            style="margin-bottom: 20px;"
        />
        <h1>{{ currReg.title }}</h1>

        <span class="category-span">RAS</span>
        <h4>{{ currReg.breed }}</h4>

        <span class="category-span">KÖN</span>
        <h4>{{ currReg.gender === 'F' ? 'T' : 'H' }}</h4>

        <span class="category-span">ÄGARE / FÖRARE</span>
        <h4>{{ currReg.ownerName }}</h4>

        <span class="category-span">CHIPNUMMER</span>
        <h4>{{ currReg.chip }}</h4>

        <span class="category-span">REGISTRERINGSNUMMER</span>
        <h4>{{ currReg.regnum }}</h4>
    </v-col>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        currReg: {
            type: Object,
            default: () => {}
        }
    }
})
</script>
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_btn, {
      color: _ctx.isNoShow ? 'error' : _ctx.getColor(_ctx.controlPassed),
      "prepend-icon": _ctx.getIcon(_ctx.controlPassed),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-control')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" FÖRHANDSKONTROLL ")
      ]),
      _: 1
    }, 8, ["color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow,
      color: _ctx.getColor(_ctx.hasEval.teeth),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.teeth),
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-bed', 'teeth')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" TÄNDER/STORLEK ")
      ]),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow,
      color: _ctx.getColor(_ctx.hasEval.basis),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.basis),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('click-bed', 'basis')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" FÖRUTSÄTTNINGAR ")
      ]),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow,
      color: _ctx.getColor(_ctx.hasEval.work_moments),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.work_moments),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('click-bed', 'work_moments')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" ARBETSMOMENT ")
      ]),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"]),
    _createVNode(_component_v_btn, {
      disabled: _ctx.isNoShow,
      color: _ctx.getColor(_ctx.hasEval.price),
      "prepend-icon": _ctx.getIcon(_ctx.hasEval.price),
      onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('click-bed', 'price')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" PRIS ")
      ]),
      _: 1
    }, 8, ["disabled", "color", "prepend-icon"])
  ], 64))
}
<template>
    <Transition>
        <v-container :class="{'green-bg': kkInstance}">
            <v-col cols="12">
                <div class="page-title d-flex justify-center mb-4">
                    <h1 v-if="kkInstance">
                        Välj besök
                    </h1>
                    <h1 v-else>
                        Välj registrering
                    </h1>
                </div>
                
                <div class="mb-8">
                    <v-text-field
                        v-model="search"
                        placeholder="Sök"
                        variant="solo"
                        append-inner-icon="mdi-magnify"
                        class="rounded-md"
                    />
                
                    <div class="d-flex justify-end mt-n4 text-subtitle-2">
                        <strong
                            v-if="search !== ''"
                            class="mr-auto"
                        >
                            Sökträffar: {{ filteredRegistrations.length }}
                        </strong>
                        <strong>
                            Registrerade
                            <span v-if="kkInstance">
                                besök:
                            </span>
                            <span v-else>hundar:</span>
                            {{ registrations.registrations.length }}
                        </strong>
                    </div>
                </div>
                <v-list
                    class="pa-0"
                    style="background: none;"
                >
                    <v-list-item
                        v-for="(reg) in filteredRegistrations"
                        v-show="reg.visible"
                        :key="reg.id"
                        elevation="2"
                        class="rounded-md mb-2 px-4 py-2 bg-white"
                        @click="$router.push({
                            name: 'RegistrationView',
                            params: {
                                'regId': reg.id,
                            }
                        })"
                    >
                        <template #prepend>
                            <v-avatar
                                :color="getAvatarColor(reg)"
                            >
                                <span v-if="reg.start_order != null">{{ reg.start_order + 1}}</span>
                            </v-avatar>
                        </template>
                        <v-list-item-title class="text-subtitle-1">
                            <strong>{{ reg.title ?? '' }}</strong>
                        </v-list-item-title>
                        <v-list-item-subtitle
                            v-if="reg.class"
                            class="text-subtitle-2"
                        >
                            Klass: {{ reg.class }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle
                            v-if="reg.breed"
                            class="text-subtitle-2"
                        >
                            Ras: {{ Array.isArray(reg.breed) ? reg.kennel.breed.join(', ') : reg.breed }}
                        </v-list-item-subtitle>
                        <v-list-item-subtitle class="text-subtitle-2">
                            {{ reg.ts ? registrationDate(reg) : '' }}
                        </v-list-item-subtitle>
                        <template #append>
                            <v-icon color="info">
                                mdi-arrow-right
                            </v-icon>
                        </template>
                    </v-list-item>
                </v-list>
            </v-col>
        </v-container>
    </Transition>
</template> 

<script lang="ts" >
import { defineComponent } from 'vue';
import { TypeRegistration, useEventStore, useRegistrationsStore, useUserStore } from '@/stores/store';
import { collection, getDocs } from 'firebase/firestore';
import db from '@/firebase/init';

export default defineComponent({
    name: 'RegistrationList',
    data() {
        return {
            userStore: useUserStore().getUser(),
            events: useEventStore(),
            registrations: useRegistrationsStore(),
            search: '',
        }
    },
    computed: {
        filteredRegistrations() {
            return this.registrations.registrations
                .filter((reg) => reg.title.toLowerCase().match(this.search.toLowerCase()))
                .sort((a, b) => {
                    if (a.start_order === null && b.start_order !== null) {
                        return -1;
                    }
                    if (b.start_order === null && a.start_order !== null) {
                        return 1
                    }
                    if (a.start_order === null && b.start_order === null) {
                        return 0;
                    }

                    // @ts-ignore
                    return a.start_order > b.start_order ? 1 : -1;
                });
        },

        isOnline() {
            return window.navigator.onLine;
        },

        kkInstance() {
            return ['kk', 'kk_dev', 'kk_test'].includes(this.userStore.getEndpoint);
        }
    },
    async mounted() {
        await this.events.init();
        if (!this.registrations.registrations.length) {
            await this.registrations.init(this.events.getEventByID(this.$route.params.eventId as string));
        }
    },
    methods: {
        getAvatarColor(reg: TypeRegistration) {
            if (reg.sentTo) {
                return 'green';
            }

            return 'surface-variant';
        },

        registrationDate(reg: TypeRegistration) {
            const locale = 'sv-SE'
            const options = {
                timeStyle: 'short'
            } as Intl.DateTimeFormatOptions;

            const date = reg.ts.toDate()

            if (date.getHours() === 1 || date.getHours() === 2) {
                return 'Heldag';
            }

            return `Tid: ${date.toLocaleTimeString(locale, options)}`
        },
    }
})

</script>

<style>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>

<template>
    <div :style="disable ? 'pointer-events: none; user-select: none; opacity: 0.5;' : ''">
        <template 
            v-for="(input, idx) in activeInputs" 
            :key="idx"
        >
            <v-row :class="[inline ? 'my-4' : 'my-0']">
                <v-col
                    v-if="(title || input.title) && inline"
                    align="left"
                    class="my-0 py-0"
                    cols="8"
                >
                    <div 
                        v-if="title" 
                        class="title"
                    >
                        {{ title }}
                    </div>
                    <div 
                        v-else 
                        class="title"
                    >
                        {{ input.title }}
                    </div>
                </v-col>
                <v-col class="my-0 py-0">
                    <div
                        v-if="!inline &&
                            (input.title || title) &&
                            !['group', 'dynamic'].includes(input.type)"
                        align="left"
                        class="inputtitle"
                    >
                        <div 
                            v-if="title"
                        >
                            {{ title }}
                        </div>
                        <div 
                            v-else
                        >
                            {{ input.title }}
                        </div>
                    </div>

                    <!-- DEBUG: {{ input.type }} {{ input.scope }} 
                        {{ selected[input.scope] }} {{ dynamic }} {{ dynamicIndex }} -->

                    <component
                        :is="'e-' + input.type"
                        :selected="selected" 
                        :input="input"
                        :hide-details="hideDetails"
                        :values="values"
                        :dynamic="dynamic"
                        :dynamic-index="dynamicIndex"
                        :registration="registration"
                        @change="onChange"
                        @set-scope="(scope: string, data: any) => setScopeData(scope, data)"
                    />

                    <div v-if="input.type === 'activator'">
                        <v-radio-group
                            v-model="selected[input.scope]"
                            style="margin-top: 0px !important;"
                            :hide-details="hideDetails"
                        >
                            <template v-if="Array.isArray(input.value)">
                                <v-radio
                                    v-for="(option, value) in input.value"
                                    :key="`${input.scope}_${value}`"
                                    :label="option.name"
                                    :value="option.value"
                                    color="info"
                                />
                            </template>
                            <template v-else>
                                <v-radio
                                    v-for="value in Object.keys(input.value)"
                                    :key="`${input.scope}_${value}`"
                                    :label="input.value[value]"
                                    :value="Number(value)"
                                    color="info"
                                />
                            </template>
                        </v-radio-group>

                        <div 
                            v-show="selected[input.scope] == input.activate_value"
                            style="margin-top: 10px;"
                        >
                            <div
                                v-for="(hInput, index) in input.hidden_inputs"
                                :key="index"
                            >
                                <div
                                    v-if="hInput.title"
                                    class="a-title"
                                >
                                    {{ hInput.title }}
                                    <v-divider />
                                </div>
                                <EvaluationInput
                                    ref="dynRefs"
                                    :inputs="hInput.inputs"
                                    :scope="hInput.scope"
                                    :values="values"
                                    @change="onChange"
                                />
                            </div>
                        </div>
                    </div>

                    <div v-if="input.type === 'dynamic'">
                        <v-expansion-panels 
                            v-model="showDynamic"
                            variant="accordion"
                        >
                            <v-expansion-panel 
                                v-for="(_, index) in selected[input.scope]"
                                :key="index"
                            >
                                <v-expansion-panel-title>
                                    <template #default>
                                        {{ input.title }} {{ index + 1 }}
                                    </template>
                                </v-expansion-panel-title>
                                <v-expansion-panel-text>  
                                    <EvaluationInput
                                        ref="dynRefs"
                                        :inputs="input.inputs"
                                        :scope="input.scope"
                                        :values="values"
                                        :dynamic="selected[input.scope]"
                                        :dynamic-index="index"
                                        :hide-details="true"
                                    />

                                    <v-btn
                                        class="mt-5"
                                        color="green"
                                        block
                                        rounded
                                        @click="showDynamic = false"
                                    >
                                        <span class="text-white">Spara {{ input.title }}</span>
                                    </v-btn>
                                    <v-btn
                                        class="mt-1"
                                        color="red"
                                        block
                                        rounded
                                        @click="removeDynamic(input.scope, index)"
                                    >
                                        <span class="text-white">Ta bort {{ input.title }}</span>
                                    </v-btn>
                                </v-expansion-panel-text>
                            </v-expansion-panel>
                        </v-expansion-panels>
                        <v-row class="mt-5 mb-4 pl-5 pr-5">
                            <v-col>
                                <v-btn
                                    color="blue"
                                    block
                                    rounded
                                    @click="addDynamic(input.scope)"
                                >
                                    <span class="text-white">Lägg till {{ input.title }}</span>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <component
                            :is="'datahandler-' + input.scope"
                            :values="selected[input.scope]"
                        />
                    </div>

                    <div v-if="input.type === 'group'">
                        <EvaluationInput
                            :title="input.title"
                            :inputs="input.inputs"
                            :scope="input.scope"
                            :values="values"
                        />
                    </div>

                    <comment-inline
                        v-if="input.comment && (input.type !== 'checkbox' || selected[input.scope])"
                        :input="input"
                        :comment="comment"
                        @update:model-value="(updatedComment) => comment = updatedComment"
                    />

                    <span 
                        v-if="warning[input.scope]" 
                        class="red--text font-weight-bold float-left text-left"
                    >
                        {{ warning[input.scope] }}
                    </span>
                </v-col>
            </v-row>

            <v-divider
                v-if="inline"
                :key="`divider-${idx}`"
            />
        </template>

        <v-divider v-if="divider && !inline" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import EvaluationRadio from './input-types/EvaluationRadio.vue'
import EvaluationRadioDropdown from './input-types/EvaluationRadioDropdown.vue'
import EvaluationCheckbox from './input-types/EvaluationCheckbox.vue'
import EvaluationCheckboxGroup from './input-types/EvaluationCheckboxGroup.vue'
import EvaluationDatetime from './input-types/EvaluationDatetime.vue'
import EvaluationDate from './input-types/EvaluationDate.vue'
import EvaluationTime from './input-types/EvaluationTime.vue'
import EvaluationTextField from './input-types/EvaluationTextField.vue'
import EvaluationComment from './input-types/EvaluationComment.vue'
import EvaluationRange from './input-types/EvaluationRange.vue'
import EvaluationDuration from './input-types/EvaluationDuration.vue'
import EvaluationLabel from './input-types/EvaluationLabel.vue'
import EvaluationChaseSelect from './input-types/EvaluationChaseSelect.vue'
import EvaluationNumberField from './input-types/EvaluationNumberField.vue'
import EvaluationPointSelectorWithMul from './input-types/EvaluationPointSelectorWithMul.vue'
import EvaluationHeader from './input-types/EvaluationHeader.vue'
import EvaluationDivider from './input-types/EvaluationDivider.vue'
import EvaluationRecived from './input-types/EvaluationRecived.vue'
import EvaluationMultipleSelect from './input-types/EvaluationMultipleSelect.vue'
import EvaluationBreedSelect from './input-types/EvaluationBreedSelect.vue'
import EvaluationEmailLink from './input-types/EvaluationEmailLink.vue'
import EvaluationCommentInline from './input-types/EvaluationCommentInline.vue'
import EvaluationWorkingTestInput from './input-types/EvaluationWorkingTestInput.vue'
import EvaluationDropdown from './input-types/EvaluationDropdown.vue'
import EvaluationBreedComment from './input-types/EvaluationBreedComment.vue'
import EvaluationBreedPreview from './input-types/EvaluationBreedPreview.vue'
import EvaluationChaseCompact from './input-types/EvaluationChaseCompact.vue'

import SummeriseRuns from './data-handlers/SummeriseRuns.vue'

import { Timestamp } from 'firebase/firestore'

export default defineComponent({
    name: 'EvaluationInput',
    components: {
        'e-radio': EvaluationRadio,
        'e-radio_dropdown': EvaluationRadioDropdown,
        'e-checkbox': EvaluationCheckbox,
        'e-checkbox_group': EvaluationCheckboxGroup,
        'e-datetime': EvaluationDatetime,
        'e-date': EvaluationDate,
        'e-time': EvaluationTime,
        'e-number': EvaluationNumberField,
        'e-text': EvaluationTextField,
        'e-comment': EvaluationComment,
        'e-range': EvaluationRange,
        'e-duration': EvaluationDuration,
        'e-chase_select': EvaluationChaseSelect,
        'e-label': EvaluationLabel,
        'e-pswm': EvaluationPointSelectorWithMul,
        'e-header': EvaluationHeader,
        'e-divider': EvaluationDivider,
        'e-recived': EvaluationRecived,
        'e-multiselect': EvaluationMultipleSelect,
        'e-breedselect': EvaluationBreedSelect,
        'e-email': EvaluationEmailLink,
        'e-wtinput': EvaluationWorkingTestInput,
        'e-dropdown': EvaluationDropdown,
        'e-breedcomment': EvaluationBreedComment,
        'e-breedpreview': EvaluationBreedPreview,
        'e-chase-compact': EvaluationChaseCompact,
        'CommentInline': EvaluationCommentInline,

        'datahandler-runs': SummeriseRuns
    },
    props: {
        title: { type: String, default: null},
        tooltip: { type: String, default: null },
        inputs: { type: Array, default: () => [] },
        values: { type: Array, default: () => [] },
        registration: { type: Object, default: null },
        radiosAsDropdown: { type: Boolean, default: false },
        inline: { type: Boolean, default: false },
        divider: { type: Boolean, default: false },
        hideDetails: { type: Boolean, default: false },
        scope: { type: String, default: null },
        dynamic: { type: Array, default: null},
        dynamicIndex: { type: Number, default: null}, 
        disable: { type: Number, default: 0 },
    },
    emits: {
        'change': null,
    },
    data() {
        return {
            comment: '',
            showTooltip: false,
            selected: {} as any,
            warning: {} as any,
            showDynamic: false,
        }
    },
    computed: {
        activeInputs () {
            return this.inputs as any;
        },
    },
    created () {
        this.activeInputs.filter((i: any) => !i.type.includes(['chase_select', 'group'])).forEach((i: any) => {
            this.selected[i.scope] = this.getValue(i);
            if (i.type === 'pswm') {
                i.values.forEach((ia: any) => {
                    this.selected[ia.scope] = this.getValue(ia);
                })
            }
        })
    },
    mounted() {
        this.$watch('selected', () => {
            this.$emit('change', this.results())
        }, { deep: true })
        this.$watch('comment', () => {
            this.$emit('change', this.results())
        })
    },
    methods: {

        setScopeData(scope: string, data: any) {
            this.selected[scope] = data;
            this.$emit('change', this.results())
        },
        
        addDynamic(scope: any) {
            this.selected[scope].push({})
        },

        removeDynamic(scope: any, idx: number) {
            this.selected[scope].splice(idx, 1)
        },

        defualtValue(type: any) {
            const regs = this.registration?.result;

            if (!type || !regs) {
                return undefined;
            }

            if(type.includes('water') && 
                type !== 'waterpassion' && 
                type !== 'water_work'
            ) {
                return regs.find((v:any) => v.type === 'water_temp')
            }
            if(type.includes('slepspor')) {
                return regs.find((v:any) => v.type === 'slepspor_temp')
            }
            return undefined;
        },

        getValue (input: any) {
            const { scope } = input;
            const defualtValue = this.defualtValue(input.scope);
            const result = this.values.find((v: any) => v.type === scope) as any;
            this.comment = result !== undefined ? result.comment : ''
            
            if (result === undefined && defualtValue) {
                const pswmInput = (this.inputs.find((v: any) => v.type === 'pswm') as any);
                if (pswmInput) {
                    const valueIn = defualtValue.value;
                    return valueIn;
                }
            }
                                    
            if (result !== undefined && typeof result.value === 'number') {
                const pswmInput = (this.inputs.find((v: any) => v.type === 'pswm') as any);
                if (pswmInput) {
                    const { values } = pswmInput as any;
                    const pswmValues = values.find((v: any) => v.scope === scope);
                    if (['EFF', 'EG', 'Ej Utförd'].includes(result.value_text)) {
                        return result.value_text;
                    }

                    if (pswmValues) {
                        const { coef } = pswmValues;
                        return result.value / coef;
                    }
                }
            }

            switch (input.type) {
                case 'date':
                    if (result !== undefined) {
                        const date = new Date(0);
                        date.setUTCMilliseconds(result.value)
                        return date.toISOString().split('T')[0];
                    }
                    return undefined;
                case 'datetime':
                    if (result !== undefined) {
                        const date = new Date(0);
                        date.setUTCMilliseconds(result.value)
                        return  date.toLocaleString('sv-SE', {timeStyle: 'short', dateStyle: 'short'});
                    }
                    return undefined;
                case 'dropdown':
                    if (result !== undefined) {
                        return result.value.map((res: any) => res.value)
                    }
                    return [];
                case 'comment':
                    return result !== undefined ? result.comment : '';
                case 'checkbox':
                    return result !== undefined ? result.value : false;
                case 'dynamic':
                    return result !== undefined ? result.value : [];
                case 'pswm':
                    return result !== undefined ? result.value : 0;
                case 'number':
                    return result !== undefined ? result.value : null;
                case 'text':
                    return result !== undefined ? result.value : '';
                case 'activator':
                case 'radio':
                    return result !== undefined ? result.value_text : input.default;
                case 'range':
                case 'duration':
                    return result !== undefined ? result.value : -1;
                default: 
                    return result !== undefined ? result.value : null;
            }
        },

        results () {
            return [
                ...Object.keys(this.selected).map((type) => {
                    const scope = this.inputs.find((i) => (i as any).scope === type) as any
                    const scope_type = scope ? scope.type : null


                    if (this.selected[type] === null || this.selected[type] === undefined) {
                        return null
                    }

                    if (scope_type === 'text' && this.selected[type] === '') {
                        return null
                    }

                    let value = this.selected[type]
                    if(scope_type === 'date') {
                        value = Timestamp.fromDate(new Date(this.selected[type])).toMillis();
                    }
                    if(scope_type === 'datetime') {
                        value = Timestamp.fromDate(new Date(this.selected[type])).toMillis();
                    }
                    if (scope_type === 'radio') {
                        const hValue = scope.value.find((v: any) => v.value === value);
                        if (hValue && Number.isInteger(hValue?.number_value)) {
                            value = hValue.number_value;
                        }
                    }

                    if (scope_type === 'comment') {
                        const c = JSON.stringify(this.selected[type]).replaceAll('\\n', '<br>');
                        value = JSON.parse(c);
                    }

                    if (scope_type === 'dropdown') {
                        const codes = scope.values.filter((inpValue: any) => value.includes(inpValue.value));
                        value = codes.map((code: any) => (
                            {
                                value: code.value,
                                subtitle: code.props.subtitle
                            }
                        ));
                    }

                    if (value === undefined && value instanceof String) {
                        value = 'none';
                    }


                    let valueText = this.getTextValue(type, this.selected[type]) ?? ''

                    if (scope_type === null && typeof value !== 'string') {
                        const pswmInputs = (this.inputs.find((v: any) => v.type === 'pswm') as any);
                        if (pswmInputs) {
                            const { values } = pswmInputs;
                            const pswmValues = values.find((v: any) => v.scope === type);
                            if (pswmValues) {
                                const { coef } = pswmValues;
                                valueText = value;
                                value *= coef;
                            }
                        }
                    }

                    if (scope_type === 'number') {
                        if (!Number.isInteger(value)) {
                            this.selected[type] = 0;
                            value = 0;
                            valueText = '0';
                        }
                    }

                    if (['EFF', 'EG', 'Ej Utförd'].includes(value)) {
                        valueText = value
                        value = 0;
                    }

                    if (scope_type === 'range') {
                        if (value < 0) {
                            valueText = 'keb';
                            value = -1;
                        }
                    }

                    return {
                        type,
                        value: Number.isNaN(value) ? 0 : value,
                        comment: scope_type === 'comment' ? this.selected[type] : this.comment || '',
                        value_text: valueText,
                        scope: this.scope ?? null,
                        dynamic: this.dynamic ?? null,
                    };
                }).filter((s) => s)
            ]
        },

        getTextValue (scope: string, value: any) {
            const input = this.inputs.find((i: any) => i.scope === scope) as any;
            if (!input) {
                return ''
            }

            if (input.type === 'radio' || input.type === 'number') {
                return `${value}`;
            }

            if (input.type === 'range') {
                if (!input.values) {
                    return `${value}`;
                }

                let index = value - input.min;
                if (input.values.some((v: any) => v.value === 0)) {
                    index = value;
                }
                return `${input.values[index]?.name ?? `${value}`}`
            }

            if (input.type === 'checkbox') {
                return value ? 'yes' : 'no';
            }

            if (input.type === 'dynamic') {
                return '';
            }

            if (typeof input.value === 'object' && input.value !== null) {
                return `${input.value[value] ?? ''}`
            }

            if (Array.isArray(input.value)) {
                return `${input.value[value] ?? ''}`
            }

            return `${input.value ?? value}`
        },


        onChange() {
            this.$emit('change', this.results())
        },


    },
})

</script>

<style lang="less">

.v-input__slot {
  margin: 0px
}

.v-slider .v-input__append {
  margin-top: 1px;
  min-width: 35px;
  font-weight: bold;
  font-size: 18px;
  background: #1976d2;
  padding: 5px 9px !important;
  border-radius: 999px;
  color: #fff;
  text-align: center;

  nobr {
      width: 100%;
      padding: 3px;
  }
}

.v-input.v-input--horizontal.v-input--density-default.v-input--dirty.v-slider {
  margin-left: 0;
}

.v-slider .v-input__details {
    align-items: unset !important;
    padding-top: unset !important;

    .v-messages {
        min-height: auto;
    }
}

.v-input--checkbox .v-messages {
  min-height: auto;
}

.a-title {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 15px;
}
</style>

<!-- eslint-disable vue/no-v-html -->
<template>
    <v-card
        align="center"
        max-height="90vh"
    >
        <v-card-title align="center">
            KONTROLLERA
        </v-card-title>
        <v-card-text>
            <v-col
                v-if="!isNoShow"
                align="center"
            >
                <div
                    v-if="controlsResults"
                    class="mb-8"
                >
                    <h4>Kontroll Resultat</h4>
                    <div
                        v-if="controlDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Namn </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <div>Hundkontroll</div>
                        <div v-if="controlsResults.controll_status === 'not_ok'">
                            Ej godkänd
                        </div>
                        <div v-else>
                            Godkänd
                        </div>
                    </div>
                    <div
                        v-if="controlsResults.reason"
                        style="display: flex; justify-content: space-between"
                    >
                        <div>Anledning</div>
                        <div>
                            {{ controlsResults.reason }}
                        </div>
                    </div>
                </div>
                <div 
                    v-if="workingtestResults"
                    class="mb-8"
                >
                    <div 
                        v-for="result in workingtestResults"
                        :key="result.scope"
                    >
                        <h4>{{ result.title }}</h4>
                        <div
                            v-if="result.done"
                            style="color: green"
                        >
                            Klarmarkerat
                        </div>
                        <div style="display: flex; justify-content: space-between">
                            <span style="font-weight: bold"> Namn </span>
                            <span style="font-weight: bold"> Värde </span>
                        </div>
                        <div>
                            <div style="display: flex; justify-content: space-between">
                                <div>
                                    <div>
                                        {{ result.name }}
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        {{ result.value }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationSteps"
                    class="mb-8"
                >
                    <h4>Spår Resultat</h4>
                    <div
                        v-if="evaluationStepDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Namn </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            v-for="(steps, idx) in evaluationSteps"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <div>
                                {{ steps.name }}
                            </div>
                            <div>
                                <div 
                                    v-for="(evals, index) in steps.evaluation"
                                    :key="index"
                                >
                                    <div
                                        v-if="!evals.name.includes('comment')"
                                    >
                                        <div style="display: flex; justify-content: space-between">
                                            <div>
                                                {{ evals.name }}
                                            </div>
                                            <div>
                                                {{ evals.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-else
                                        style="display: flex; flex-direction: column;"
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    {{ evals.name }}
                                                </div>
                                            </div>
                                            <div style="text-align: left;">
                                                <div
                                                    v-html="evals.value"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationChase"
                    class="mb-8"
                >
                    <h4>Drev Resultat</h4>
                    <div
                        v-if="evaluationChaseDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Namn </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            class="evaluation-results"
                        >
                            <div style="display: flex; justify-content: space-between">
                                <div>
                                    Drev dag
                                </div>
                                <div>
                                    {{ evaluationChase.chase_day }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(drev, idx) in evaluationChase.drev"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <div>
                                {{ drev.name }}
                            </div>
                            <div>
                                <div 
                                    v-for="(evals, index) in drev.results"
                                    :key="index"
                                >
                                    <div v-if="evals.name === 'Upptag'">
                                        <div
                                            v-for="(uptake) in evals.value"
                                            :key="uptake.name"
                                        >
                                            <div style="display: flex; justify-content: space-between">
                                                <div>
                                                    {{ uptake.name }}
                                                </div>
                                                <div>
                                                    {{ uptake.value }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="!evals.name.includes('comment')"
                                    >
                                        <div style="display: flex; justify-content: space-between">
                                            <div>
                                                {{ evals.name }}
                                            </div>
                                            <div>
                                                {{ evals.value }}
                                            </div>
                                        </div>
                                    </div>
                                    <div 
                                        v-else
                                        style="display: flex; flex-direction: column;"
                                    >
                                        <div>
                                            <div>
                                                <div>
                                                    {{ evals.name }}
                                                </div>
                                            </div>
                                            <div style="text-align: left;">
                                                <div
                                                    v-html="evals.value"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationForestChase"
                    class="mb-8"
                >
                    <h4>Drev Resultat</h4>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Namn </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            v-for="(evaluation, idx) in evaluationForestChase"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <h5>Drev {{ idx + 1 }}</h5>
                            <div
                                v-for="(value, key) in evaluation"
                                :key="key"
                            >
                                <div
                                    style="display: flex; justify-content: space-between"
                                >
                                    <div>
                                        <div>
                                            {{ key }}
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            {{ value }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="evaluationResults"
                    class="mb-8"
                >
                    <h4>Bedömning Resultat</h4>
                    <div
                        v-if="evaluationDone"
                        style="color: green"
                    >
                        Klarmarkerat
                    </div>
                    <div style="display: flex; justify-content: space-between">
                        <span style="font-weight: bold"> Namn </span>
                        <span style="font-weight: bold"> Värde </span>
                    </div>
                    <div>
                        <div
                            v-for="(evaluation, idx) in evaluationResults"
                            :key="idx"
                            class="evaluation-results"
                        >
                            <div 
                                v-if="!textareaFields.includes(evaluation.name)"
                            >
                                <div
                                    v-if="evaluation.name !== 'no_show'"
                                    style="display: flex; justify-content: space-between; flex-wrap: wrap;"
                                >
                                    <div>
                                        <div style="white-space: nowrap; padding-right: 4em;">
                                            {{ evaluation.name }}
                                        </div>
                                    </div>
                                    <div>
                                        <div style="text-align: right;">
                                            {{ evaluation.value }}
                                        </div>
                                    </div>
                                </div>
                                <div 
                                    v-if="evaluation.comment"
                                    style="display: flex; justify-content: space-between; flex-wrap: wrap;"
                                >
                                    <div>
                                        <div style="white-space: nowrap; padding-right: 4em;">
                                            <small><b>- Kommentar</b></small><br>
                                        </div>
                                    </div>
                                    <div>
                                        <div style="text-align: left;">
                                            {{ evaluation.comment }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <div
                                    style="display: flex; flex-direction: column;"
                                >
                                    <div>
                                        <div>
                                            {{ evaluation.name }}
                                        </div>
                                    </div>
                                    <div style="text-align: left;">
                                        <div
                                            v-html="evaluation.value"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
            <v-col
                v-else
                align="center"
            >
                <h1>Kom ej till start</h1>
            </v-col>
        </v-card-text>
        <v-card-actions>
            <v-col align="center">
                <small
                    v-if="!canSendToSKK"
                    style="opacity: 0.5;"
                >
                    Allt måste vara klarmarkerat för att kunna skicka in resultat
                </small>
                <small v-else-if="!isOnline">
                    Du måste ha internet för att skicka resultaten till SKK Start
                </small>
                <v-btn
                    color="info"
                    block
                    variant="tonal"
                    :disabled="skkAjaxLock || !canSendToSKK || !isOnline"
                    @click="$emit('previewGood')"
                >
                    Skicka till SKK Start Web
                </v-btn>
                <v-btn
                    color="primary"
                    block
                    variant="tonal"
                    style="margin-inline-start: 0px !important"
                    @click="$emit('previewBad')"
                >
                    Tillbaka
                </v-btn>
            </v-col>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts">
import { useEvaluationsStore, TypeRegistration, useUserStore, TypeEvent} from '@/stores/store';
import { defineComponent } from 'vue';

import skf from '@/assets/jsonnet/skf.jsonnet';
import fa from '@/assets/jsonnet/fa.jsonnet';
import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet';
import chase from '@/assets/jsonnet/chase.jsonnet';
import taxchase from '@/assets/jsonnet/taxchase.jsonnet';
import adbchase from '@/assets/jsonnet/adbchase.jsonnet';
import bassetchase from '@/assets/jsonnet/bassetchase.jsonnet';
import huntingretriever from '@/assets/jsonnet/ssrk.jsonnet';
import hunting_swk from '@/assets/jsonnet/hunting_swk.jsonnet';
import hunting_swk_hunting from '@/assets/jsonnet/hunting_swk_hunting.jsonnet';
import huntingspaniel from '@/assets/jsonnet/ssrkspan.jsonnet';
import hunting_mock from '@/assets/jsonnet/huntingmock.jsonnet';
import huntingfunction from '@/assets/jsonnet/ssrkfunction.jsonnet';
import hunting_tolling from '@/assets/jsonnet/huntingtolling.jsonnet';
import hunting_tolling_practical from '@/assets/jsonnet/huntingtolling_practical.jsonnet';
import hunting_swk_forest from '@/assets/jsonnet/hunting_swk_forest.jsonnet';
import hunting_tjtk_skott_vatten from '@/assets/jsonnet/hunting_tjtk_skott_vatten.jsonnet';
import hunting_tjtk_anlagsprov_skog from '@/assets/jsonnet/hunting_tjtk_anlagsprov_skog.jsonnet';
import hunting_tjtk_anlagsprov_falt from '@/assets/jsonnet/hunting_tjtk_anlagsprov_falt.jsonnet';
import hunting_tjtk_anlagsprov_vildsvin from '@/assets/jsonnet/hunting_tjtk_anlagsprov_vildsvin.jsonnet';

import skk from './translations/skk.json';

const extrasMap = {
    'bloodtracking': ['conditions'],
    'hunting_tolling': ['workshot', 'summary', 'price'],
    'hunting_tolling_practical': ['condition', 'workshot', 'summary', 'price'],
    'chase': ['extra', 'chasestory'],
    'chase_basset': ['extra', 'chasestory'],
    'chase_adb': ['extra', 'chasestory'],
    'chase_tax': ['extra', 'chasestory'],
    'hunting_swk': ['property_workmoments', 'release', 'price', 'test_story'],
    'hunting_tjtk': ['property_workmoments', 'release', 'price', 'test_story'],
    'hunting_tjtk_anlagsprov_skog': ['teeth', 'work_moments', 'price'],
    'hunting_tjtk_skott_vatten': ['teeth', 'work_moments', 'price'],
    'hunting_tjtk_anlagsprov_falt': ['teeth', 'work_moments', 'price'],
    'hunting_tjtk_anlagsprov_vildsvin': ['teeth', 'work_moments', 'price'],
} as { [key: string]: Array<String> };

function checkExtras(
    evals: Array<{id: string, done: boolean|undefined}>,
    extras: Array<String>
): boolean {
    const filteredEvals = evals.filter((v) => extras.includes(v.id));
    const evalDone = filteredEvals.flatMap((v) => v?.done || false);
    return evalDone.every((v) => v) || false
};

export default defineComponent({
    name: 'PreviewSkkResult',
    props: {
        currEvent: {
            type: Object,
            required: true,
        },
        currReg: {
            type: Object,
            required: true,
        },
        skkAjaxLock: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['previewBad', 'previewGood'],
    data() {
        const textareaFields = [
            'Domarberättelse', 
            'Domarens kommentarer', 
            'Domarkommentar',
            'Drevprovsberättelse',
            'Sammanfattande Kritik',
            'Provberättelse',
            'Anteckningsruta',
            'Första lockarbetet, sammanfattning',
            'Andra lockarbetet, sammanfattning',
            'Ev ytterligare lockarbeten, sammanfattning',
            'Arbete efter skott'
        ];
        return {
            evaluationStore: useEvaluationsStore(),
            userStore: useUserStore().getUser(),
            tracker: 0,
            textareaFields,
        };
    },
    computed: {
        isOnline() {
            return window.navigator.onLine;
        },

        translations() {
            return skk.translations as { [key: string]: string };
        },

        isSearch() {
            return this.currEvent.categories.findIndex((type:any) => type.value === 'Eftersök') !== -1;
        },

        canSendToSKK() {
            if (this.currEvent.type === 'working_test' ) {
                return (this.controlDone && this.workingtestResults?.length) || this.isNoShow;
            }

            if (this.evaluationSteps) {
                return this.controlDone && 
                    this.evaluationDone &&
                    this.evaluationStepDone || 
                    this.isNoShow;
            }

            if (this.evaluationChase) {
                return this.controlDone && 
                    this.evaluationDone &&
                    this.evaluationChaseDone || 
                    this.isNoShow;
            }

            return this.controlDone && this.evaluationDone || this.isNoShow;
        },

        controlDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const findResult = this.evaluationStore.evaluations.find((v) => v.id === 'control');

            if (!findResult) {
                return null;
            }

            return findResult.done || null;
        },

        controlsResults() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const findResult = this.evaluationStore.evaluations.find((v) => v.id === 'control');

            if (!findResult) {
                return null;
            }

            return findResult.control_result || null;
        },

        swkHuntingExtraDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const evals = this.evaluationStore.evaluations;
            const hasChase = evals.find((v) => v.id === 'chase');

            const evalDone = [
                ...evals.find((v) => v.id === 'moment')?.done || false,
                ...hasChase ? (hasChase?.done || false) : true,
                ...evals.find((v) => v.id === 'price')?.done || false,
            ];

            return evalDone.every((v) => v) || null;
        },

        evaluationDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            let evalDocName = 'evaluation'

            if (
                this.currEvent.type === 'bloodtracking' ||
                this.currEvent.type === 'chase' ||
                this.currEvent.type === 'chase_tax' ||
                this.currEvent.type === 'chase_adb' ||
                this.currEvent.type === 'chase_basset'
            ) {
                evalDocName = 'registration'
            }

            if (['hunting_tolling', 'hunting_tolling_practical'].includes(this.currEvent.type)) {
                evalDocName = 'baitwork'
            }

            if ([
                'hunting_swk',
                'hunting_tjtk',
                'hunting_tjtk_skott_vatten',
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.currEvent.type)) {
                evalDocName = 'basis'
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                evalDocName = 'condition'
            }

            if (this.currEvent.type === 'hunting_swk_forest') {
                evalDocName = 'performance'
            }


            const evalDone = this.evaluationStore.evaluations.find((v) => v.id === evalDocName);

            if (!evalDone) {
                return null;
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                return (evalDone.done && this.swkHuntingExtraDone) || null;
            }

            const extras = extrasMap[this.currEvent.type];
            if (extras) {
                const evals = this.evaluationStore.evaluations;

                return evalDone.done && checkExtras(evals, extras);
            }

            return evalDone.done || null;
        },

        evaluationStepDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const evalDone = this.evaluationStore.evaluations.find((v) => v.id === 'steps');

            if (!evalDone) {
                return null;
            }

            return evalDone.done || null;
        },

        evaluationChaseDone() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            const evalDone = this.evaluationStore.evaluations.find((v) => v.id === 'chase');

            if (!evalDone) {
                return null;
            }

            return evalDone.done || null;
        },

        evaluationSteps() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = this.evaluationStore.evaluations.find((v) => v.id === 'steps');

            if (!findResult) {
                return null;
            }

            const valueObject = { ...findResult };
            delete valueObject.id;
            delete valueObject.metadata;
            delete valueObject.path;
            delete valueObject.done;

            const retArray = [] as any;
            let evals: any[] = [];
            let steps;

            switch (this.currEvent.type) {
                case 'bloodtracking':
                    if (this.currReg.class === 'Anlagsklass') {
                        evals = bloodtracking.steps.anlagsklass;
                        steps = bloodtracking.step_list.anlagsklass;
                    } else if (this.currReg.class === 'Öppen klass') {
                        evals = bloodtracking.steps.oppen_klass;
                        steps = bloodtracking.step_list.oppen_klass;
                    }
                    break;
                default:
                    return [];

            }

            if (!evals.length) {
                return [];
            }

            steps.forEach((step: { id: number, name: string, stateName: string }) => {
                const evaluation = evals.find((ival: any) => ival.state === step.stateName);

                // @ts-ignore
                const header = this.translations[evaluation.state] || evaluation.state;
                const children: any[] = [];

                evaluation.evaluation.inputs.forEach((input: { scope: string, type: string, value: unknown }) => {
                    const inputValue = valueObject[input.scope];

                    if (!inputValue) {
                        return;
                    }

                    children.push({
                        // @ts-ignore
                        name: this.translations[inputValue.type] || inputValue.type,
                        // @ts-ignore
                        value: this.translations[inputValue.value_text] || inputValue.value_text
                    });
                });

                if (children.length) {
                    retArray.push(
                        {
                            name: header,
                            evaluation: children
                        }
                    );
                }
            })

            return retArray;
        },

        workingtestResults() {
            if (!this.evaluationStore.evaluations.length || this.currEvent.type !== 'working_test') {
                return null;
            }

            const stations = [ ...this.currEvent.stations ];
            const allowedStations = stations.filter((v: any) => v.referee_user_ids.includes(this.userStore.user.id));
            const evals = [] as any[];

            allowedStations.forEach((allowedStation: any) => {
                const stationResults = this.evaluationStore.evaluations.find((v) => v.id === `station_${allowedStation.station_number}`);
                if (!stationResults) {
                    return;
                }

                if (!Object.prototype.hasOwnProperty.call(stationResults, 'station_input')) {
                    return;
                }
                const { station_input } = stationResults;
                const { done } = stationResults;

                evals.push(
                    {
                        title: `Station ${allowedStation.station_number}`,
                        scope: station_input.type,
                        // @ts-ignore
                        name: this.translations[station_input.type] || station_input.type,
                        // @ts-ignore
                        value: typeof station_input.value === 'string' ? this.translations[station_input.value] : station_input.value,
                        done: done || false
                    }
                );
                
            });

            return evals;
        },

        evaluationForestChase() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = { ...this.evaluationStore.evaluations.find((v) => v.id === 'chase') };

            if (!Object.hasOwn(findResult, 'forest_runs')) {
                return null;
            }

            const fruns = findResult.forest_runs;

            if (!(Object.hasOwn(fruns, 'value') && fruns.value.length)) {
                return null;
            }

            return fruns.value.map((run: any) => {
                const e = Object.entries(run).sort((a, b) => a[0].localeCompare(b[0]));
                const ret = e.flatMap((ent: any) => 
                    [
                        [this.translations[ent[0]], this.translations[ent[1]] ?? ent[1]]
                    ]
                );

                return Object.fromEntries(ret);
            });
        },

        evaluationChase() {
            if (['hunting_swk_hunting', 'hunting_swk_forest'].includes(this.currEvent.type)) {
                return null;
            }
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }

            const findResult = { ...this.evaluationStore.evaluations.find((v) => v.id === 'chase') };

            if (!Object.values(findResult).length) {
                return null;
            }

            const chaseOrdering = {
                'Släpp': 1,
                'Slag': 2,
                'Upptag': 3,
                'Sista skall': 4,
                'Inkall': 5,
                'Kopplad': 6,
                'Skallfrekvens': 7,
                'Viltslag': 8,
                'Använt pejl för inkallning?': 9
            } as { [key: string]: number };

            const chaseRet = {
                chase_day: findResult.chase_day ? findResult.chase_day.value : 'Ingen dag vald',
                drev: [] as Array<{name: string, results: Array<{name: string, value: any}>}>
            } as { [key: string]: any };


            delete findResult.done;
            delete findResult.id;
            delete findResult.chase_day;
            delete findResult.metadata;
            delete findResult.path;

            Object.entries(findResult).forEach(([key, value]: [string, any]) => {
                const keySplit = key.split('_');
                // @ts-ignore
                const relaeaseName = `${this.translations[keySplit[0]]} ${Number(keySplit[1]) + 1}`

                const drev = {
                    name: relaeaseName,
                    results: [] as Array<{name: string, value: any}>
                }
                
                Object.entries(value.results).forEach(([resKey, resValue]: [string, any]) => {

                    if (typeof resValue === 'string') {
                        if (resValue.split(':').length === 2) {
                            drev.results.push({
                                name: this.translations[resKey] || resKey,
                                value: resValue
                            })
                        } else {
                            drev.results.push({
                                name: this.translations[resKey] || resKey,
                                value: this.translations[resValue]
                            })
                        }
                    }
                    
                    if (typeof resValue === 'number') {
                        drev.results.push({
                            name: this.translations[resKey] || resKey,
                            value: resValue
                        })
                    }
                    if (Array.isArray(resValue)) {
                        resValue.forEach((uptake, index) => {
                            if (uptake.from === '' && uptake.to === '') {
                                return;
                            }

                            let upptag = drev.results.find((v) => v.name === 'Upptag');

                            if (!upptag) {
                                upptag = {
                                    name: 'Upptag',
                                    value: []
                                }

                                drev.results.push(upptag);
                            }

                            upptag.value.push({
                                name: `Drev ${index + 1}`,
                                value: `från ${uptake.from} till ${uptake.to}` 
                            })
                        })
                    }


                })
                
                drev.results.sort((a, b) => chaseOrdering[a.name] - chaseOrdering[b.name]);
                chaseRet.drev.push(drev);
            })

            chaseRet.drev.sort((a: any, b: any) => Number(a.name.split(' ')[1]) - Number(b.name.split(' ')[1]));
            return chaseRet;
        },

        evaluationResults() {
            if (!this.evaluationStore.evaluations.length) {
                return null;
            }
            if (this.currEvent.type === 'working_test') {
                return null;
            }
            let additionalEvals = null;
            let evalDocName = 'evaluation'

            if (this.currEvent.type === 'bloodtracking') {
                evalDocName = 'registration'
                additionalEvals = this.evaluationStore.evaluations.find((v) => v.id === 'conditions')
            }

            if (['hunting_tolling', 'hunting_tolling_practical'].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'baitwork';
                const evalIds = ['workshot', 'summary', 'price'];
                if (this.currEvent.type === 'hunting_tolling_practical') {
                    evalIds.push('condition');
                }
                additionalEvals = evals
                    .filter((v) => evalIds.includes(v.id))
                    .reduce((a, c) => Object.assign(a, c), {});
            }

            if (this.currEvent.type === 'hunting_swk_forest') {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'performance';
                additionalEvals = evals
                    .filter((v) => ['test_story', 'results'].includes(v.id))
                    .reduce((a, c) => Object.assign(a, c), {});
            }

            if (
                this.currEvent.type === 'chase' || 
                this.currEvent.type === 'chase_basset' ||
                this.currEvent.type === 'chase_tax' ||
                this.currEvent.type === 'chase_adb'
            ) {
                evalDocName = 'registration'
                additionalEvals = { ...this.evaluationStore.evaluations.find((v) => v.id === 'chasestory'), ...this.evaluationStore.evaluations.find((v) => v.id === 'extra')}
            }

            if (['hunting_swk', 'hunting_tjtk'].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'basis'
                additionalEvals = {
                    ...evals.find((v) => v.id === 'property_workmoments'),
                    ...evals.find((v) => v.id === 'release'),
                    ...evals.find((v) => v.id === 'price'),
                    ...evals.find((v) => v.id === 'test_story'),
                }
            }

            if (this.currEvent.type === 'hunting_swk_hunting') {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'condition'
                const chaseEval = evals.find((v) => v.id === 'chase') ? evals.find((v) => v.id === 'chase') : {};

                additionalEvals = {
                    ...evals.find((v) => v.id === 'moment'),
                    ...chaseEval,
                    ...evals.find((v) => v.id === 'price'),
                }
            }
            if ([
                'hunting_tjtk_skott_vatten',
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.currEvent.type)) {
                const evals = this.evaluationStore.evaluations;
                evalDocName = 'basis'
                additionalEvals = {
                    ...evals.find((v) => v.id === 'teeth'),
                    ...evals.find((v) => v.id === 'work_moments'),
                    ...evals.find((v) => v.id === 'price'),
                }
            }

            let findResult = this.evaluationStore.evaluations.find((v) => v.id === evalDocName);

            if (additionalEvals) {
                findResult = { ...findResult, ...additionalEvals };
            }

            if (!findResult) {
                return null;
            }

            const valueObject = { ...findResult };
            delete valueObject.id;
            delete valueObject.metadata;
            delete valueObject.path;
            delete valueObject.done;

            const retArray = [] as any;
            let evals;

            switch (this.currEvent.type) {
                case 'bird_skf':
                    if (this.currReg.class === 'SKL endast Breton') {
                        evals = fa.standard.skl.evaluation;
                        break;
                    }
                    switch (this.currReg.breed) {
                        case 'BRAQUE FRANCAIS, TYPE PYRÉNÉES':
                        case 'EPAGNEUL BRETON':
                        case 'BRETON':
                            evals = skf.breton.evaluation;
                            break;
                        case 'BRACCO ITALIANO':
                        case 'SPINONE ITALIANO':
                        case 'SPINONE':
                            if (this.currReg.class.includes('EKL')) {
                                evals = skf.brac_spin_ekl.evaluation;
                                break;
                            }
                            evals = skf.brac_spin.evaluation;
                            break;
                        case 'SLOVENSKÝ HRUBOSRSTY STAVAC (OHAR)':
                        case 'GRIFFON D\'ARRET À POIL DUR/KORTHALS':
                        case 'GRIFFON D\'ARRET A POIL DUR':
                        case 'WEIMARANER, KORTHÅRIG':
                        case 'WEIMARANER KURZHAARIG':
                        case 'WEIMARANER, LÅNGHÅRIG':
                        case 'WEIMARANER LANGHAARIG':
                            if (
                                this.currReg.class.includes('EKL') &&
                                this.currEvent.categories.findIndex((eventEntry: { value: string; type: string }) =>
                                    eventEntry.value.includes('Internationellt'),
                                ) !== -1
                            ) {
                                evals = skf.grif_weim_srhp_ekl_int.evaluation;
                                break;
                            }
                            if (
                                this.currReg.class.includes('EKL') &&
                                this.currEvent.categories.findIndex((eventEntry: { value: string; type: string }) =>
                                    eventEntry.value.includes('Nationellt'),
                                ) !== -1
                            ) {
                                evals = skf.grif_weim_srhp_ekl_nat.evaluation;
                                break;
                            }
                            evals = skf.grif_weim_srhp.evaluation;
                            break;
                        default:
                            if (this.currReg.class.includes('EKL')) {
                                evals = skf.drent_gdh_stab_other_ekl.evaluation;
                                break;
                            }
                            evals = skf.drent_gdh_stab_other.evaluation;
                            break;
                    }
                    break;
                case 'bird_fa':
                    switch (this.currReg.class) {
                        case 'SKL endast Breton':
                        case 'SKL':
                            evals = fa.standard.skl.evaluation;
                            break;
                        case 'UKL 9-24 mån': 
                            evals = fa.standard.ukl.evaluation;
                            break;
                        case 'Apport':
                            evals = fa.standard.apport.evaluation;
                            break;
                        case 'ÖKL över 9 mån':
                        default: 
                            evals = fa.standard.okl.evaluation;
                            break;
                    }
                    break;
                case 'fa_highstatus':
                    switch (this.currReg.class) {
                        case 'SKL Internationell':
                        case 'SKL endast Breton':
                        case 'SKL':
                            evals = fa.highstatus.skl.evaluation;
                            break;
                        case 'UKL 9-24 mån': 
                        default: 
                            evals = fa.highstatus.ukl.evaluation;
                            break;
                    }
                    break;
                case 'hunting_retriever_a':
                    // eslint-disable-next-line no-case-declarations
                    const test_type = (this.currEvent as TypeEvent).categories.find((category) => category.type === 'Provslag')?.value;

                    if (test_type === 'Nationellt') {
                        switch (this.currReg.class) {
                            case 'ÖKL':
                                evals = huntingretriever.type_a.okl_nat.evaluation;
                                break;
                            case 'EKL':
                                evals = huntingretriever.type_a.ekl_nat.evaluation;
                                break;
                            default:
                                evals = huntingretriever.type_a.okl_nat.evaluation;
                                break;
                        }
                    } else {
                        evals = huntingretriever.type_a.ekl_int.evaluation;
                    }

                    break;
                case 'hunting_retriever_b':
                    evals = huntingretriever.type_b.evaluation;
                    break;
                case 'hunting_spaniel_vatten':
                    evals = huntingspaniel.water.evaluation;
                    break;
                case 'hunting_spaniel_nyborjare':
                    evals = huntingspaniel.beginner.evaluation;
                    break;
                case 'hunting_spaniel_falt':
                    switch (this.currReg.class) {
                        case 'Öppenklass':
                            evals = huntingspaniel.okl.evaluation;
                            break;
                        case 'Segrarklass enkelsläpp':
                        case 'Segrarklass parsläpp':
                            evals = huntingspaniel.skl.evaluation;
                            break;
                        default:
                            evals = huntingspaniel.skl.evaluation;
                            break;
                    }
                    break;
                case 'hunting_spaniel_wt':
                    evals = huntingspaniel.working.evaluation;
                    break;
                case 'bloodtracking':
                    evals = [...bloodtracking.evaluation.conditions, ...bloodtracking.evaluation.registration];
                    break;
                case 'chase_basset':
                    evals = [
                        ...bassetchase.evaluation.registration,
                        ...bassetchase.evaluation.extra,
                        ...bassetchase.evaluation.chasestory
                    ];
                    break;
                case 'chase_tax':
                    evals = [
                        ...taxchase.evaluation.registration,
                        ...taxchase.evaluation.extra,
                        ...taxchase.evaluation.chasestory
                    ];
                    break;
                case 'chase_adb':
                    evals = [
                        ...adbchase.evaluation.registration,
                        ...adbchase.evaluation.extra,
                        ...adbchase.evaluation.chasestory
                    ];
                    break;
                case 'chase':
                    evals = [
                        ...chase.evaluation.registration, 
                        ...chase.evaluation.extra, 
                        ...chase.evaluation.chasestory
                    ];
                    break;
                case 'hunting_tjtk':
                case 'hunting_swk':
                    evals = [
                        ...hunting_swk.basis,
                        ...hunting_swk.property_workmoments,
                        ...hunting_swk.release,
                        ...hunting_swk.price,
                        ...hunting_swk.test_story
                    ];
                    break;
                case 'hunting_swk_hunting':
                    evals = [
                        ...hunting_swk_hunting.other.condition,
                        ...hunting_swk_hunting.other.moment,
                        ...hunting_swk_hunting.other.price,
                    ];
                    break;
                case 'hunting_swk_forest':
                    evals = [
                        ...hunting_swk_forest.performance,
                        ...hunting_swk_forest.test_story,
                        ...hunting_swk_forest.results,
                    ];
                    break;
                case 'hunting_tolling':
                    evals = [
                        ...hunting_tolling.baitwork,
                        ...hunting_tolling.workshot,
                        ...hunting_tolling.summary,
                        ...hunting_tolling.price
                    ];
                    break;
                case 'hunting_tolling_practical':
                    evals = [
                        ...hunting_tolling_practical.condition,
                        ...hunting_tolling_practical.baitwork,
                        ...hunting_tolling_practical.workshot,
                        ...hunting_tolling_practical.summary,
                        ...hunting_tolling_practical.price
                    ];
                    break;
                case 'hunting_mock_trial':
                    evals = hunting_mock.evaluation;
                    break;
                case 'hunting_retriever_function':
                    evals = huntingfunction.evaluation;
                    break;
                case 'hunting_tjtk_anlagsprov_skog':
                    evals = [
                        ...hunting_tjtk_anlagsprov_skog.teeth,
                        ...hunting_tjtk_anlagsprov_skog.basis,
                        ...hunting_tjtk_anlagsprov_skog.work_moments,
                        ...hunting_tjtk_anlagsprov_skog.price
                    ];
                    break;
                case 'hunting_tjtk_skott_vatten':
                    evals = [
                        ...hunting_tjtk_skott_vatten.teeth,
                        ...hunting_tjtk_skott_vatten.basis,
                        ...hunting_tjtk_skott_vatten.work_moments,
                        ...hunting_tjtk_skott_vatten.price
                    ];
                    break;
                case 'hunting_tjtk_anlagsprov_falt':
                    evals = [
                        ...hunting_tjtk_anlagsprov_falt.teeth,
                        ...hunting_tjtk_anlagsprov_falt.basis,
                        ...hunting_tjtk_anlagsprov_falt.work_moments,
                        ...hunting_tjtk_anlagsprov_falt.price
                    ];
                    break;
                case 'hunting_tjtk_anlagsprov_vildsvin':
                    evals = [
                        ...hunting_tjtk_anlagsprov_vildsvin.teeth,
                        ...hunting_tjtk_anlagsprov_vildsvin.basis,
                        ...hunting_tjtk_anlagsprov_vildsvin.work_moments,
                        ...hunting_tjtk_anlagsprov_vildsvin.price
                    ];
                    break;
                default:
                    evals = [];
                    break;
            }

            if (!evals.length) {
                return [];
            }

            if (this.isSearch) {
                evals = evals.filter((item:any) => item.title !== 'Tilläggspris')
            }

            evals.forEach((evaluation: any) => {
                const scopes = evaluation.inputs.map((input: any) => ({
                    scope: input.scope,
                    // @ts-ignore
                    name: this.translations[input.scope] || input.scope,
                    type: input.type,
                    values: input.values ?? null,
                }));

                scopes.forEach((scope: any) => {
                    if (!scope.scope) {
                        return;
                    }

                    const value = valueObject[scope.scope];

                    if (!value) {
                        return;
                    }

                    if (scope.name.includes('Sammanfattande kritik')) {
                        retArray.push({
                            name: scope.name,
                            value: value.value_text,
                        });
                        return;
                    }

                    if (scope.name === 'Summering' && scope.values) {
                        const value_scopes = scope.values.map((v2: any) => ({
                            scope: v2.scope,
                            // @ts-ignore
                            name: this.translations[v2.scope] || v2.scope,
                        }));

                        value_scopes.forEach((value_scope: any) => {
                            if (!value) {
                                return;
                            }

                            retArray.push({
                                name: value_scope.name,
                                // @ts-ignore
                                value: this.translations[value.value_text] || value.value,
                            });
                        });
                        return;
                    }

                    if (scope.name.includes('koder') && scope.values) {
                        if (!value) return;

                        const codes = value.value;

                        if (!codes) return;

                        const stringArray = codes.map((code: { value: string }) => (
                            `${scope.values.find((v: {value: string}) => v.value === code.value).name}`
                        )) as Array<String>;

                        retArray.push({
                            name: scope.name,
                            value: stringArray.join(', '),
                        });
                        return;
                    }

                    if (scope.name === 'Tilläggsregistrering tassdjur' || scope.name === 'Tilläggsregistrering klövdjur') {
                        const chaseResults = this.evaluationStore.evaluations.find((v) => v.id === 'chase');

                        if (!chaseResults) {
                            return;
                        }

                        const chaseEntries = Object.entries(chaseResults);

                        const [,selectedChase] = chaseEntries.find(
                            ([,cValue]: [string, any]) => cValue.id === value.value
                        ) as [string, any];

                        if (!selectedChase) {
                            return;
                        }

                        if (!selectedChase.results && !Object.prototype.hasOwnProperty.call(selectedChase.results, 'gamekind')) {
                            return;
                        }

                        retArray.push({
                            name: scope.name,
                            value: this.translations[selectedChase.results.gamekind] || selectedChase.results.gamekind
                        });
                        return;
                    }


                    if (Array.isArray(value.value)) {
                        if (scope.type === 'checkbox_group') {
                            const translated = value.value.map((v: string) => this.translations[v]);
                            retArray.push({
                                name: this.translations[scope.scope],
                                value: translated.join(', '),
                                comment: value.comment ?? null
                            });
                            return;
                        }

                        if (['registration_terrain_types'].includes(scope.scope)) {
                            value.value.forEach((v: string) => {
                                retArray.push({
                                    // @ts-ignore
                                    name: this.translations[v],
                                    value: 'Ja'
                                });
                            });
                            return;
                        }

                        value.value.forEach((valueObj: any, idx: number) => {
                            const entriesC = Object.entries(valueObj).map((val) => [
                                // @ts-ignore
                                `${['bloodtracking', 'hunting_swk_hunting', 'bird_skf'].includes(this.currEvent.type) ? 'Släpp' : 'Pris'} ${idx + 1} - ${this.translations[val[0]] || val[0]}`,
                                val[1],
                            ]) as [string, unknown][];

                            entriesC.forEach((v: [string, unknown]) => {
                                retArray.push({
                                    name: v[0],
                                    // @ts-ignore
                                    value: typeof v[1] === 'string' ? this.translations[v[1]] : v[1],
                                });
                            });
                        });
                        return;
                    }

                    if (this.currEvent.type === 'hunting_swk_hunting') {
                        const inclArr = [
                            'searchwork_forest',
                            'waterwork',
                            'chase_nose',
                            'chase_bark',
                            'chase_track_will',
                            'chase_track_security',
                            'rigour',
                            'shot_reaction',
                        ];
                        if (inclArr.includes(scope.scope)) {
                            let text_value = `${value.value} - ${this.translations[value.value_text] ?? value.value_text}`
                            if (scope.scope === 'shot_reaction') {
                                text_value = value.value
                            }

                            retArray.push({
                                name: scope.name,
                                value: text_value,
                                comment: value.comment ?? null,
                            });
                            return;
                        }

                        if (scope.type === 'chase-compact' || scope.type === 'comment') {
                            value.value = 0;

                            retArray.push({
                                name: scope.name,
                                value: value.value_text,
                            });
                            return;
                        }
                    }

                    if (['Provslag', 'Provtyp', 'Provform', 'Klass'].includes(scope.name)) {
                        value.value_text = value.value;
                    }
                    
                    if (['Spår lagt', 'Datum gjord'].includes(scope.name)) {
                        const date = new Date(value.value);
                        value.value = date.toLocaleDateString('sv-SE', { hour: '2-digit', minute: '2-digit' });
                    }

                    if (value.value === -1) {
                        retArray.push({
                            name: scope.name,
                            value: 'KEB',
                        });
                        return;
                    }

                    if (this.currEvent.type === 'hunting_tolling_practical' && scope.scope === 'price') {
                        retArray.push({
                            name: scope.name,
                            value: this.translations[value.value_text],
                            comment: value.comment ? value.comment : ''
                        });
                        return;
                    }

                    if (scope.type === 'range') {
                        let pushValue =`${value.value_text}(${value.value})`

                        if (value.value === 0) {
                            pushValue = value.value_text
                        }

                        retArray.push({
                            name: this.translations[scope.scope],
                            value: pushValue,
                            comment: value.comment ? value.comment : ''
                        });
                        return;
                    }

                    const comment = !scope.scope.includes('comment') && value.comment ? value.comment : '';

                    retArray.push({
                        name: scope.name,
                        // @ts-ignore
                        value: this.translations[value.value_text] || value.value,
                        comment
                    });
                });
            });

            if (Object.prototype.hasOwnProperty.call(valueObject, 'no_show')) {
                retArray.unshift({
                    // @ts-ignore
                    name: valueObject.no_show.type,
                    value: valueObject.no_show.value,
                });
            }

            return retArray.filter(
                (value: any, index: number, self: any) =>
                    index === self.findIndex((t: any) => t.name === value.name && t.value === value.value),
            );
        },

        isNoShow() {
            if (!this.evaluationResults) {
                return 0;
            }
            if (this.evaluationResults.length && this.evaluationResults[0].name === 'no_show') {
                return this.evaluationResults[0].value;
            }
            return 0;
        },
    },
    created() {
        this.evaluationStore.init(this.currReg as TypeRegistration);
    },
});
</script>

<style>
.evaluation-results:nth-child(even) {
  background-color: rgb(240, 240, 240);
}
</style>

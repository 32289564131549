<template>
    <v-container
        :class="{'green-bg': kkInstance}"
        class="relative"
    >
        <div
            v-if="kkCheckMap[user.getEndpoint]"
            style="position: sticky; top: 0; z-index: 1000;"
        >
            <v-alert
                v-model="showErrorAlert"
                type="error"
                position="sticky"
                class="full-width"
            >
                {{ errorAlertMessage }}
            </v-alert>
            <v-alert
                v-model="showSentAlert"
                :type="isOnline ? 'success' : 'error'"
                position="sticky"
                class="full-width"
            >
                {{ sentAlertMessage }}
            </v-alert>
            <v-alert
                v-if="isOnline"
                v-model="showUploadAlert"
                type="warning"
                position="sticky"
                class="full-width upload-alert"
            >
                <div class="content">
                    Ta ett kort på blanketten och ladda upp till protokollet på
                    <a
                        style="color: white"
                        :href="kennelLink"
                    >www.skkkonsulent.se</a>
                </div>
                <v-btn 
                    color="white" 
                    density="compact"
                    variant="plain"
                    @click="showUploadAlert = false"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-alert>
        </div>
        <v-alert
            v-else
            v-model="showSentAlert"
            type="success"
            position="sticky"
            class="full-width"
        >
            Resultatet är skickat till SKK Start
        </v-alert>

        <component 
            :is="'dialog-' + event.type"
            :registration="registration"
            :show-control="openControl"
            @cancle-control="openControl = false"
            @control-ok="controlPassed = 1"
            @control-bad="handleBadControl"
            @no-show="handleNoShow"
        />

        <suspense @resolve="getEvaluations()">
            <registration-info
                v-model:currentRegistration="registration" 
                v-model:currentEvent="event" 
            />

            <template #fallback>
                <div class="layout-container-fullscreen layout-padding-top">
                    <v-progress-circular
                        indeterminate
                        color="var(--green-3)"
                        size="150"
                        width="20"
                        class="mb-8"
                    />
                </div>
            </template>
        </suspense>

        <v-col class="action-col">
            <component 
                :is="'action-' + event.type"
                :curr-reg="registration"
                :curr-event="event"
                :control-passed="controlPassed"
                :is-no-show="isNoShow"
                @click-bed="goToEvaluation"
                @click-control="openControl = true"
                @click-paid="setPaidStatus"
            />

            <div
                v-if="user.getEndpoint.includes('skk')"
            >
                <v-divider class="mt-2 mb-10" />

                <v-btn
                    color="info"
                    block
                    @click="showSkkPreview = true"
                >
                    Granska resultat
                </v-btn>

                <v-dialog 
                    v-model="showSkkPreview" 
                    contained
                >
                    <PreviewSkkResults
                        :curr-event="event"
                        :curr-reg="registration"
                        :skk-ajax-lock="ajaxLock"
                        @preview-bad="showSkkPreview = false"
                        @preview-good="sendToSKK()"
                    />
                </v-dialog>

                <v-btn
                    v-if="resultLink"
                    color="info"
                    block
                    :href="resultLink"
                    target="_blank"
                >
                    Visa Resultat i SKK Start
                </v-btn>
            </div>
            <div
                v-else-if="kkCheckMap[user.getEndpoint]"
            >
                <!-- <v-btn
                    color="info"
                    block
                    @click="showKkPreview = true"
                >
                    Granska resultat
                </v-btn>
                <v-dialog 
                    v-model="showKkPreview" 
                    contained
                >
                    <PreviewSkkResults
                        :curr-event="event"
                        :curr-reg="registration"
                        :skk-ajax-lock="ajaxLock"
                        @preview-bad="showKkPreview = false"
                        @preview-good="sendToKK()"
                    />
                </v-dialog> -->

                <v-btn
                    color="info"
                    block
                    @click="sendToKK"
                >
                    Skicka för förhandsgranskning
                </v-btn>

                <v-btn
                    v-if="kennelLink"
                    color="info"
                    block
                    :href="kennelLink"
                    target="_blank"
                >
                    Visa protokollet på SKK Konsulent Web
                </v-btn>
            </div>
        </v-col>
    </v-container>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import RegistrationInfo from '@/components/RegistrationInfo.vue'

import SKFActions from '@/components/registration/skf/Actions.vue'
import FAActions from '@/components/registration/fa/Actions.vue'
import BloodtrackingActions from '@/components/registration/bloodtracking/Actions.vue'
import ChaseActions from '@/components/registration/chase/Actions.vue'
import KennelvisitActions from '@/components/registration/kennelvisit/Actions.vue'
import WorkinktestActions from '@/components/registration/workingtest/Actions.vue'
import HuntingRetrieverActions from '@/components/registration/hunting_retriever/Actions.vue'
import HuntingSWKActions from '@/components/registration/hunting_swk/Actions.vue'
import HuntingSWKHuntingActions from '@/components/registration/hunting_swk_hunting/Actions.vue'
import HuntingSWKForestActions from '@/components/registration/hunting_swk_forest/Actions.vue'
import HuntingMockActions from '@/components/registration/hunting_mock/Action.vue'
import HuntingFunctionActions from '@/components/registration/hunting_function/Action.vue'
import HuntingTollingActions from '@/components/registration/hunting_tolling/Actions.vue'
import HuntingTollingPracticalActions from '@/components/registration/hunting_tolling/ActionsPractical.vue'
import HuntingTJTKActions from '@/components/registration/hunting_tjtk/Actions.vue'

import KennelvisitDialog from '@/components/registration/kennelvisit/Dialog.vue'
import RegistrationDialogs from '@/components/registration/RegistrationDialogs.vue'

import PreviewSkkResults from '@/components/shared/PreviewSkkResults.vue'

import bloodtracking from '@/assets/jsonnet/bloodtracking.jsonnet'

import { TypeEvent, TypeRegistration, useEvaluationsStore, useRegistrationsStore, useUserStore } from '@/stores/store'
import { uploadResults } from '@/api/Api'
import { Axios, AxiosError } from 'axios'

export default defineComponent({
    name: 'RegistrationView',
    components: {
        RegistrationInfo,
        PreviewSkkResults,

        'action-bird_skf': SKFActions,
        'dialog-bird_skf': RegistrationDialogs,
        
        'action-bird_fa': FAActions,
        'dialog-bird_fa': RegistrationDialogs,

        'action-fa_highstatus': FAActions,
        'dialog-fa_highstatus': RegistrationDialogs,

        'action-bloodtracking': BloodtrackingActions,
        'dialog-bloodtracking': RegistrationDialogs,
        
        'action-chase': ChaseActions,
        'dialog-chase': RegistrationDialogs,

        'action-chase_tax': ChaseActions,
        'dialog-chase_tax': RegistrationDialogs,

        'action-chase_basset': ChaseActions,
        'dialog-chase_basset': RegistrationDialogs,

        'action-chase_adb': ChaseActions,
        'dialog-chase_adb': RegistrationDialogs,

        'action-kennelvisit': KennelvisitActions,
        'dialog-kennelvisit': KennelvisitDialog,

        'action-working_test': WorkinktestActions,
        'dialog-working_test': RegistrationDialogs,

        'action-hunting_retriever_a': HuntingRetrieverActions,
        'dialog-hunting_retriever_a': RegistrationDialogs,

        'action-hunting_swk': HuntingSWKActions,
        'dialog-hunting_swk': RegistrationDialogs,

        'action-hunting_swk_hunting': HuntingSWKHuntingActions,
        'dialog-hunting_swk_hunting': RegistrationDialogs,
        
        'action-hunting_swk_forest': HuntingSWKForestActions,
        'dialog-hunting_swk_forest': RegistrationDialogs,

        'action-hunting_retriever_b': HuntingRetrieverActions,
        'dialog-hunting_retriever_b': RegistrationDialogs,

        'action-hunting_spaniel_vatten': HuntingRetrieverActions,
        'dialog-hunting_spaniel_vatten': RegistrationDialogs,

        'action-hunting_spaniel_nyborjare': HuntingRetrieverActions,
        'dialog-hunting_spaniel_nyborjare': RegistrationDialogs,

        'action-hunting_spaniel_falt': HuntingRetrieverActions,
        'dialog-hunting_spaniel_falt': RegistrationDialogs,

        'action-hunting_spaniel_wt': HuntingRetrieverActions,
        'dialog-hunting_spaniel_wt': RegistrationDialogs,

        'action-hunting_mock_trial': HuntingMockActions,
        'dialog-hunting_mock_trial': RegistrationDialogs,

        'action-hunting_retriever_function': HuntingFunctionActions,
        'dialog-hunting_retriever_function': RegistrationDialogs,

        'action-hunting_tolling': HuntingTollingActions,
        'dialog-hunting_tolling': RegistrationDialogs,

        'action-hunting_tolling_practical': HuntingTollingPracticalActions,
        'dialog-hunting_tolling_practical': RegistrationDialogs,

        'action-hunting_tjtk': HuntingSWKActions,
        'dialog-hunting_tjtk': RegistrationDialogs,

        'action-hunting_tjtk_skott_vatten': HuntingTJTKActions,
        'dialog-hunting_tjtk_skott_vatten': RegistrationDialogs,

        'action-hunting_tjtk_anlagsprov_skog': HuntingTJTKActions,
        'dialog-hunting_tjtk_anlagsprov_skog': RegistrationDialogs,

        'action-hunting_tjtk_anlagsprov_falt': HuntingTJTKActions,
        'dialog-hunting_tjtk_anlagsprov_falt': RegistrationDialogs,

        'action-hunting_tjtk_anlagsprov_vildsvin': HuntingTJTKActions,
        'dialog-hunting_tjtk_anlagsprov_vildsvin': RegistrationDialogs,
    },
    data() {
        return {
            registration: {} as TypeRegistration,
            event: {} as TypeEvent,
            evaluations: useEvaluationsStore(),
            registrationStore: useRegistrationsStore(),
            user: useUserStore().getUser(),
            openControl: false,
            controlPassed: -1,
            controlBadReason: '',
            showSkkPreview: false,
            showKkPreview: false,
            ajaxLock: false,
            showSentAlert: false,
            showUploadAlert: false,
            sentAlertMessage: '',
            isOnline: true,
            epToUrl: {
                'skktest': 'https://skk-test.smoothcomp.dev',
                'skkdev': 'https://skk-dev.smoothcomp.dev',
                'skkdev2': 'https://skk-dev2.smoothcomp.dev',
                'skkdev3': 'https://skk-dev3.smoothcomp.dev',
                'skkstart': 'https://skkstart.se',
                'skklocal': 'http://smoothcomp.test',
                'kk': 'https://skkkonsulent.se',
                'kk_dev': 'https://kennelkonsulenterna-frontend-dev-h2mujzxlyq-ez.a.run.app',
                'kk_test': 'https://kennelkonsulenterna-frontend-test-h2mujzxlyq-ez.a.run.app'
            } as { [key: string]: string },
            kkCheckMap: {
                'kk': true,
                'kk_dev': true,
                'kk_test': true
            } as { [key:string]: boolean },
            sentTo: '',
            showErrorAlert: false,
            errorAlertMessage: '',
        }
    },
    computed: {
        kennelLink() {
            return `${this.epToUrl[this.user.endpoint]}/past-bookings`;
        },

        resultLink() {
            return this.sentTo !== '' ? this.sentTo : this.registration?.sentTo;
        },

        isNoShow() {
            let evalDocName = 'evaluation'

            if (this.event.type === 'bloodtracking') {
                evalDocName = 'registration'
            }

            return this.evaluations.isNoShow(evalDocName,  ['kk', 'kk_dev', 'kk_test'].includes(this.user.getEndpoint));
        },

        kkInstance() {
            return ['kk', 'kk_dev', 'kk_test'].includes(this.user.getEndpoint);
        }
    },
    mounted() {
        this.showSkkPreview = this.$route.query.openPreview === 'true';
        window.addEventListener('online', () => {this.isOnline = true});
        window.addEventListener('offline', () => {this.isOnline = false});
    },
    methods: {

        getEvaluations() {
            this.evaluations.init(this.registration).then(() => this.updateControl()).catch(console.log)
        },

        updateControl() {
            const control = this.evaluations.evaluations.find((value) => value.id === 'control');

            if (control && control.done) {
                if (control.control_result && control.control_result.controll_status === 'not_ok') {
                    this.controlBadReason = control.control_result.reason;
                    this.controlPassed = 0;
                } else if (control.control_result && control.control_result.controll_status === 'ok') {
                    this.controlPassed = 1;
                }
            }
            return {};
        },

        goToEvaluation(type: any, additionalParams?: object) {
            this.$router.push({ 
                name: 'evaluation',
                params: { type, ...(additionalParams || {})}
            })
        },

        handleBadControl(reason: string) {
            this.controlBadReason = reason;
            this.controlPassed = 0;
        },

        async handleNoShow() {
            let evalDocName = 'evaluation';

            if (this.event.type === 'bloodtracking') {
                evalDocName = 'registration';
            }

            if (this.isNoShow) {
                await this.registrationStore.addResult(
                    this.registration.id as string, 
                    evalDocName, 
                    [
                        {
                            comment: '',
                            type: 'no_show',
                            value: 0,
                            value_text: 'Kom ej till start',
                            scope: null,
                            dynamic: null,
                        }
                    ]
                );

                this.registrationStore.MarkIncomplete(this.registration.id as string, evalDocName);
                this.openControl = false;
                return;
            }

            await this.registrationStore.addResult(
                this.registration.id as string, 
                evalDocName, 
                [
                    {
                        comment: '',
                        type: 'no_show',
                        value: 1,
                        value_text: 'Kom ej till start',
                        scope: null,
                        dynamic: null,
                    }
                ]
            );
            this.registrationStore.MarkDone(this.registration.id as string, evalDocName);
            this.openControl = false;
        },

        setPaidStatus(value: boolean) {
            this.registration.paid = value;
            this.registrationStore.setPaidStatus(this.registration.id, value);
        },

        sendToKK() {
            const visitCompleted = this.evaluations.evaluations.filter((v: { id: string }) => v.id === 'visit')[0] as any;
            if (!visitCompleted || visitCompleted.visit_completed.value === 'no') {
                this.errorAlertMessage = 'Du måste ange om protokollet är klart under kategori "Besöket"!';
                this.showErrorAlert = true;
                return;  
            }
            if (this.ajaxLock) {
                return;
            }

            this.ajaxLock = true;

            this.sentAlertMessage = this.isOnline ? 'Protokollet är skickat till www.skkkonsulent.se' : 'Protokollet kunde inte skickas då du saknar uppkoppling till internet!';
            this.showSentAlert = true;
            this.showUploadAlert = true;
            setTimeout(() => {
                this.showSentAlert = false;
            }, 5000)

            this.ajaxLock = false;
        },

        sendToSKK() {
            if (this.ajaxLock) {
                return;
            }

            this.ajaxLock = true;

            const control = this.evaluations.evaluations.filter((v: { id: string }) => v.id === 'control')[0] as any;
            let obj = { ...this.evaluations.evaluations.filter((v: { id: string }) => v.id === 'registration' || v.id === 'evaluation' || v.id === 'basis')[0] as any};

            if (this.event.type === 'bloodtracking') {
                obj = { ...this.evaluations.evaluations.filter((v: { id: string}) => v.id === 'conditions')[0] as any, ...obj };
            }

            if (['hunting_tolling', 'hunting_tolling_practical'].includes(this.event.type)) {
                const evalIds = ['baitwork', 'workshot', 'summary', 'price'];
                if (this.event.type === 'hunting_tolling_practical') {
                    evalIds.push('condition');
                }
                obj = this.evaluations.evaluations
                    .filter((v: { id: string}) => evalIds.includes(v.id))
                    .reduce((a: any, c: any) => Object.assign(a, c), {});
            }

            if (this.event.type === 'working_test' && this.event.stations) {
                const stationsEvals = this.evaluations.evaluations.filter((v: {id: string}) => 
                    this.event.stations?.flatMap((b: any) => `station_${b.station_number}`).includes(v.id)
                ).reduce((cEvals: any, nEvals) => (
                    { ...cEvals, [nEvals.id]: { ...nEvals.station_input } }
                ), {});

                obj = { ...stationsEvals }
            }

            if (['chase', 'chase_basset', 'chase_tax', 'chase_adb'].includes(this.event.type)) {

                const extra = { ...this.evaluations.evaluations.filter((v: { id: string}) => v.id === 'extra')[0] as any};
                const story = { ...this.evaluations.evaluations.filter((v: { id: string}) => v.id === 'chasestory')[0] as any }
                const rewards = {} as { [key: string]: any };
                if (extra.reward && Array.isArray(extra.reward.value)) {
                    extra.reward.value?.forEach((v: any, idx: number) => {
                        rewards[`reward_game_${idx}`] = {
                            type: `reward_game_${idx}`,
                            comment: '',
                            value: v.hunting_game,
                            value_text: v.hunting_game
                        }
                        rewards[`reward_value_${idx}`] = {
                            type: `reward_value_${idx}`,
                            comment: '',
                            value: v.value,
                            value_text: `${v.value}`,
                        }
                    });

                    delete extra.reward;
                } else if (extra.reward && extra.hunting_game) {
                    rewards.reward_game_0 = {
                        type: 'reward_game_0',
                        comment: '',
                        value: extra.hunting_game.value,
                        value_text: `${extra.hunting_game.value}`
                    }
                    rewards.reward_value_0 = {
                        type: 'reward_value_0',
                        comment: '',
                        value: extra.reward.value,
                        value_text: `${extra.reward.value}`,
                    }

                    delete extra.reward;
                }

                if (extra.reward_d_cert && Object.prototype.hasOwnProperty.call(extra.reward_d_cert, 'comment')) {
                    extra.reward_d_cert.comment = '';
                }
                
                obj = {
                    ...obj,
                    ...story,
                    ...extra,
                    ...rewards
                }
            }

            if (this.event.type === 'hunting_swk_forest') {
                const storeEvals = this.evaluations.evaluations;
                obj = {
                    ...storeEvals.find((v) => v.id === 'performance'),
                    ...storeEvals.find((v) => v.id === 'test_story'),
                    ...storeEvals.find((v) => v.id === 'chase'),
                    ...storeEvals.find((v) => v.id === 'results'),
                }
            }

            if (['hunting_swk', 'hunting_tjtk'].includes(this.event.type)) {
                const storeEvals = this.evaluations.evaluations;
                obj = {
                    ...obj,
                    ...storeEvals.find((v) => v.id === 'property_workmoments'),
                    ...storeEvals.find((v) => v.id === 'release'),
                    ...storeEvals.find((v) => v.id === 'price'),
                    ...storeEvals.find((v) => v.id === 'test_story'),
                }
            }
            if (this.event.type === 'hunting_swk_hunting') {
                const storeEvals = this.evaluations.evaluations;
                obj = {
                    ...obj,
                    ...storeEvals.find((v) => v.id === 'condition'),
                    ...storeEvals.find((v) => v.id === 'moment'),
                    ...storeEvals.find((v) => v.id === 'chase'),
                    ...storeEvals.find((v) => v.id === 'price'),
                }
            }
            
            if ([
                'hunting_tjtk_anlagsprov_skog',
                'hunting_tjtk_skott_vatten',
                'hunting_tjtk_anlagsprov_falt',
                'hunting_tjtk_anlagsprov_vildsvin'
            ].includes(this.event.type)) {
                const storeEvals = this.evaluations.evaluations;
                obj = {
                    ...obj,
                    ...storeEvals.find((v) => v.id === 'teeth'),
                    ...storeEvals.find((v) => v.id === 'work_moments'),
                    ...storeEvals.find((v) => v.id === 'price'),
                }
            }

            const stepsObj = { ...this.evaluations.evaluations.find((v: { id: string }) => v.id === 'steps') };
            const chaseObj = { ...this.evaluations.evaluations.find((v: { id: string }) => v.id === 'chase') };


            const rounds = [];

            if (this.event.type === 'bloodtracking' && stepsObj && !this.isNoShow) {
                const steps: any[] = [];
                const stepsEvalutaions: any[] = [];

                let steplist: any[] = [];
                let stepInputs: any[] = [];

                delete stepsObj.path;
                delete stepsObj.id;
                delete stepsObj.metadata;
                delete stepsObj.done;
                
                if (this.registration.class === 'Anlagsklass') {
                    steplist = bloodtracking.step_list.anlagsklass;
                    stepInputs = bloodtracking.steps.anlagsklass;
                } else if (this.registration.class === 'Öppen klass') {
                    steplist = bloodtracking.step_list.oppen_klass;
                    stepInputs = bloodtracking.steps.oppen_klass;
                }

                steplist.forEach((step) => {
                    const stepInput = stepInputs.find((v) => v.state === step.stateName);
                    const stepTimes = stepsObj[step.stateName];
                    const stepEvalutaions: any[] = [];

                    stepInput.evaluation.inputs.forEach((input: any) => {
                        const evaluation = stepsObj[input.scope];
                        if (evaluation) {
                            stepEvalutaions.push({ 
                                type: evaluation.type, 
                                comment: evaluation.comment, 
                                value: evaluation.value, 
                                value_text: evaluation.value_text,
                            });
                        }
                    });

                    let elapsed = 0;

                    if (steps.find((v: any) => v.state === 'start')) {
                        elapsed = 1;
                        const startTime = steps[0].timestamp;

                        if (startTime) {
                            if (step.stateName === 'stop') {
                                const endTime = stepTimes.worldTime.toMillis();
                                elapsed = Math.abs(startTime - endTime);
                            }
                        }
                    }

                    steps.push({
                        timestamp: stepTimes.worldTime.toMillis(),
                        elapsed,
                        state: stepTimes.stateName,
                        comment: stepEvalutaions.find((v) => v.type.includes('comment'))?.value_text ?? '',
                    });

                    stepsEvalutaions.push(...stepEvalutaions.filter((v) => !v.type.includes('comment')));
                });

                rounds.push({
                    comment: '',
                    results: stepsEvalutaions,
                    steps,
                })
            }

            if (['chase', 'chase_basset', 'chase_tax', 'chase_adb'].includes(this.event.type) && chaseObj && !this.isNoShow) {
                const { chase_day } = chaseObj;
                const chaseObjCopy = { ...chaseObj };
                

                delete chaseObjCopy.done;
                delete chaseObjCopy.id;
                delete chaseObjCopy.path;
                delete chaseObjCopy.metadata;
                delete chaseObjCopy.chase_day;

                const mapToSteps = {
                    'release_slag': 'slag',
                    'recall': 'inkall',
                    'leashed': 'stop'
                } as { [key: string]: any }

                const sortedEntries = Object.entries(chaseObjCopy).sort(
                    ([, aV]: [string, any], [, bV]: [string, any]) => {
                    
                        if(aV.id > bV.id) {
                            return 1;
                        }
                        if (aV.id < bV.id) {
                            return -1;
                        }
                        return 0;
                    });

                sortedEntries.forEach(([, value]: [string, any]) => {
                    const stepsEvaluations: any[] = [];
                    const steps: any[] = [];

                    const startTimestamp = Date.parse(`${chase_day.value} ${value.results.release_time}`);

                    if (Number.isNaN(startTimestamp)) {
                        return;
                    }

                    steps.push({
                        timestamp: startTimestamp,
                        elapsed: 0,
                        state: 'start',
                        comment: '',
                    });


                    const resultEntries = Object.entries(value.results);
                    resultEntries.sort((a: [string, any], b: [string, any]) => {

                        const sortPriority = {
                            release_time: 6,
                            release_slag: 5,
                            release_uptake: 4,
                            last_bark: 3,
                            recall: 2,
                            leashed: 1
                        } as { [key: string]: any }

                        if (sortPriority[a[0]] && !sortPriority[b[0]]) {
                            return -1;
                        }
                        if (sortPriority[b[0]] && !sortPriority[a[0]]) {
                            return 1;
                        }
                        if (!sortPriority[b[0]] && !sortPriority[a[0]]) {
                            return 0;
                        }

                        if (sortPriority[a[0]] > sortPriority[b[0]]) {
                            return -1;
                        }

                        if (sortPriority[b[0]] > sortPriority[a[0]]) {
                            return 1;
                        }

                        return 0;
                    })

                    const recall = resultEntries.find(([key, _]) => key === 'recall');

                    resultEntries.forEach(([resKey, resValue]: [string, any]) => {

                        steps.sort((a: any, b: any) => a.timestamp > b.timestamp ? 1 : -1)

                        if (Array.isArray(resValue)) {
                            const upptakeValue = resValue[0];

                            const dateParseFrom = Date.parse(`${chase_day.value} ${upptakeValue.from}`);

                            if (upptakeValue.from === '') {
                                return;
                            }

                            steps.push({
                                timestamp: dateParseFrom,
                                elapsed: Math.abs(dateParseFrom - startTimestamp),
                                state: 'upptag',
                                comment: '',
                                chase_time: upptakeValue.chase_time,
                                tappt_time: upptakeValue.tappt_time,
                            });

                            if (resValue.length) {
                                const amoutOfDrops = resValue.length - 1;

                                // eslint-disable-next-line no-loops/no-loops
                                for (let drop = 0; drop <= amoutOfDrops; drop += 1) {

                                    const thisStep = resValue[drop];
                                    const nextStep = resValue[drop + 1];

                                    if (thisStep.from === '' || thisStep.to === '') {
                                        return;
                                    }

                                    const stepDateParseFrom = Date.parse(`${chase_day.value} ${thisStep.from}`)
                                    const stepDateParseTo = Date.parse(`${chase_day.value} ${thisStep.to}`)
                                    let elapsedBetween = 0;
                                    if (nextStep && nextStep.from) {
                                        elapsedBetween = Math.abs(stepDateParseTo - Date.parse(`${chase_day.value} ${nextStep.from}`));
                                    } else if (recall != null && typeof recall[1] === 'string') {
                                        elapsedBetween = Math.abs(stepDateParseTo - Date.parse(`${chase_day.value} ${recall[1]}`))
                                    }

                                    steps.push({
                                        timestamp: stepDateParseTo,
                                        elapsed: Math.abs(stepDateParseFrom - startTimestamp),
                                        state: 'tappt',
                                        comment: '',
                                        chase_time: nextStep.chase_time,
                                        tappt_time: nextStep.tappt_time,
                                        results: [
                                            {
                                                comment: '',
                                                type: 'tappt.duration',
                                                value: elapsedBetween,
                                                value_text: '',
                                            }
                                        ]
                                    });
                                }

                            }

                            return;
                        }

                        if (typeof resValue !== 'string') {
                            return;
                        }


                        if (!mapToSteps[resKey]) {
                            return;
                        }

                        const dateParse = Date.parse(`${chase_day.value} ${resValue}`);

                        const step = {
                            timestamp: dateParse,
                            elapsed: Math.abs(dateParse - startTimestamp),
                            state: mapToSteps[resKey],
                            comment: '',
                        } as { [key: string]: any};

                        if (mapToSteps[resKey] === 'stop') {
                            const gk = resultEntries.find(([reKey, _]: [string, any]) => reKey === 'gamekind')?.[1];
                            const bf = resultEntries.find(([reKey, _]: [string, any]) => reKey === 'bark_frequency')?.[1];
                            const hp = resultEntries.find(([reKey, _]: [string, any]) => reKey === 'hunting_pejl')?.[1] as string;
                            const hpValueString = hp.charAt(0).toUpperCase() + hp.slice(1);

                            if (gk && hpValueString) {
                                step.results = [
                                    {
                                        comment: '',
                                        type: 'hunting_game',
                                        value: gk,
                                        value_text: `${gk}`
                                    },
                                    {
                                        comment: '',
                                        type: 'hunting_pejl',
                                        value: hpValueString,
                                        value_text: `${hpValueString}`
                                    }
                                ]
                            }

                            if (bf) {
                                stepsEvaluations.push(
                                    {
                                        comment: '',
                                        type: 'bark_frequency',
                                        value: bf,
                                        value_text: `${bf}`
                                    }
                                )
                            }
                        }

                        steps.push(step);
                    })

                    rounds.push({
                        comment: '',
                        results: stepsEvaluations,
                        steps,
                    })
                })
            }

            if (obj.registration_terrain_types) {
                obj.registration_terrain_types.value.forEach((terrainType: string) => {
                    obj[terrainType] = {
                        comment: '',
                        dynamic: null,
                        scope: null,
                        type: terrainType,
                        value: true,
                        value_text: terrainType
                    }
                });

                delete obj.registration_terrain_types;
            }
            
            const asArray = Object.entries(obj);

            const filteredArray = asArray.filter(([key]) => 
                key !== 'path' && 
                key !== 'id' &&
                key !== 'metadata' &&
                key !== 'done'
            );
            const validation_comment = [
                'validation_comment',
                { 
                    type: 'validation_comment', 
                    comment: control?.control_result?.reason ?? '', 
                    value: control?.control_result?.controll_status === 'ok' ? 1 : 0,
                    value_text: control?.control_result?.controll_status === 'ok' ? 'Verified ID' : '', 
                }
            ];


            // @ts-ignore
            const mappedArray = filteredArray.map(([key, value]: any) => [
                key,
                { 
                    type: value.type, 
                    comment: value.comment, 
                    value: value.value, 
                    value_text: value.value_text,
                }
            ]);
            mappedArray.push(validation_comment);
            const filteredObj = Object.fromEntries(mappedArray);

            if (Object.prototype.hasOwnProperty.call(filteredObj, 'runs')) {
                filteredObj.runs.value.forEach((run: any, idx: number) => {
                    const keys = Object.keys(run);
                    keys.forEach((key) => {
                        filteredObj[`${key}_${idx}`] = {
                            type: `${key}_${idx}`, 
                            comment: '', 
                            value: run[key], 
                            value_text: `${run[key]}`
                        };
                    });
                });

                delete filteredObj.runs;
            }

            let results = { ...filteredObj };
            
            if (this.event.type === 'bloodtracking' && rounds[0].results.length) {
                // eslint-disable-next-line security/detect-unsafe-regex
                const pattern = /([_a-z]+)(_(\d+))?_evaluation$/;
                const patternTwo = /([_a-z]+)(_(\d+[_a-z]+))/;

                const filteredSteps = rounds[0].results.reduce((a, v) => {
                    v.type = v.type.replace(pattern, '$1$3');
                    v.type = v.type.includes('return') ?  v.type.replace(patternTwo, '$1$3') : v.type
                    v.type = v.type.includes('blood_break') ?  v.type.replace(patternTwo, '$1$3') : v.type
                    return {
                        ...a, [v.type]: v
                    }
                }, {})

                results = {...filteredSteps,...filteredObj}
            }

            uploadResults('registration', this.registration.paid , {
                id: this.registration.id,
                status: 'completed',
                rounds,
                results
            }).then((res) => {
                this.ajaxLock = false;
                this.showSkkPreview = false;

                const linkTo = `${this.epToUrl[this.user.endpoint]}/skk/registrations/${this.registration.id}`;
                this.registrationStore.MarkSent(this.registration.id as string, linkTo);
                this.sentTo = linkTo;

                this.showSentAlert = true;
                setTimeout(() => {
                    this.showSentAlert = false;
                }, 5000)
                
                return res;
            }).catch((err: AxiosError) => {
                const errMessages = ['Kunde inte skicka resultat!']

                const code = err?.code;
                const isOnline = window.navigator.onLine;

                if (!isOnline) {
                    errMessages.push('Du värkar inte vara online!\n\
                    För att skicka resultatet se till att du är uppkopplad');
                }
                if (code) {
                    errMessages.push(`Kod: ${code}`);
                }

                alert(errMessages.join('\n'));
                this.ajaxLock = false;
            })
        }
    },
})
</script>

<style lang="less">
.action-col {
    button {
        width: 500vw;
        margin-bottom: 15px;
    }
}

.upload-alert {
    margin-top: 2rem;

    .content {
        margin-right: 2rem;
        display: inline-block;
    }

    a {
        color: white;
        display: inline-block;
    }

    button {
        position: absolute;
        top: 15px;
        right: -5px;
    }
}
</style>

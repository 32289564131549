import axios, { AxiosResponse, AxiosInstance, AxiosError } from 'axios';

export function login(data: any): Promise<AxiosResponse> {
    const url = `${baseApi()}/login`;
    return createRequest('post', url, data);
}

export function getEvents(): Promise<AxiosResponse> {
    const url = `${baseApi()}/dogs/huntingtest/events`;
    return createRequest('get', url, null);
}

export function getRegistrations(): Promise<AxiosResponse> {
    const url = `${baseApi()}/dogs/huntingtest/registrations`;
    return createRequest('get', url, null);
}

export function uploadResults(
    type: string, 
    paid: boolean, 
    payload: any
): Promise<AxiosResponse> {
    const url = `${baseApi()}/dogs/huntingtest/${type}`
    return createRequest('post', url,
        {
            id: payload.id,
            status: payload.status,
            paid,
            type: payload.type,
            rounds: payload.rounds,
            results: payload.results
        }
    );
}

//////////////////////////////////////////////////////////////////////////

function baseApi(): string {
    if (localStorage.getItem('skk-base-api') !== null) {
        return localStorage.getItem('skk-base-api') as string;
    }

    return `${window.location.origin}/api/v1`;
}

function createRequest(
    method: 'get' | 'post' | 'delete' | 'update',
    url: string,
    data: any,
): Promise<AxiosResponse> {
    const ax = axiosInstance() as { [key: string]: any };

    return new Promise((resolve, reject) => {
        ax[method](url, data)
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    if (response.headers.authorization) {
                        jwtUpdate(response.headers.authorization);
                    }
                    resolve(response);
                    return response;
                }

                console.log('response was not 200');
                reject(response);
                return response;
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 401) {
                    reject(error.response);
                    if ((error.response.data as any).error === 'invalid_credentials') {
                        alert('Invalid email/password combination');
                    }
                // } else if (
                //     error.response &&
                //     (error.response.data as any).error === 'token_invalid'
                // ) {
                //     reject(error.response);
                } else {
                    reject(error.response);
                }
            });
    });
}

function axiosInstance(): AxiosInstance {
    const options: {
        headers: {
            [key: string]: any;
        };
    } = {
        headers: {
            'Access-Control-Allow-Origin': '*',
        },
    };

    const j = jwt();
    if (j) {
        options.headers.Authorization = `Bearer ${j}`;
    }

    return axios.create(options);
}

function jwt(): string {
    // @ts-ignore
    return localStorage.getItem('jwtoken') ?? window.jsonToken;
}


function jwtUpdate(authorizationHeader: any): void {
    const jwtoken = authorizationHeader.replace(/bearer /i, '');
    if (jwtoken) {
        localStorage.setItem('jwtoken', jwtoken);
    }
}

<template>
    <v-btn
        :color="isNoShow ? 'error' : getColor(controlPassed)"
        :prepend-icon="getIcon(controlPassed)"
        @click="$emit('click-control')"
    >
        FÖRHANDSKONTROLL
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.teeth)"
        :prepend-icon="getIcon(hasEval.teeth)" 
        @click="$emit('click-bed', 'teeth')"
    >
        TÄNDER/STORLEK
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.basis)"
        :prepend-icon="getIcon(hasEval.basis)" 
        @click="$emit('click-bed', 'basis')"
    >
        FÖRUTSÄTTNINGAR
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.work_moments)"
        :prepend-icon="getIcon(hasEval.work_moments)" 
        @click="$emit('click-bed', 'work_moments')"
    >
        ARBETSMOMENT
    </v-btn>
    <v-btn
        :disabled="isNoShow"
        :color="getColor(hasEval.price)"
        :prepend-icon="getIcon(hasEval.price)" 
        @click="$emit('click-bed', 'price')"
    >
        PRIS
    </v-btn>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import {
    useRegistrationsStore
} from '@/stores/store'

export default defineComponent({
    props: {
        controlPassed: {
            type: Number,
            default: -1
        },
        currReg: {
            type: Object,
            required: true,
        },
        isNoShow: {
            type: Boolean,
            default: false,
        }
    },
    emits: {
        'click-bed': null,
        'click-paid': null,
        'click-control': null
    },
    data() {
        return {
            registration: useRegistrationsStore(),
            hasEval: {
                teeth: -1,
                basis: -1,
                work_moments: -1,
                price: -1,
            }
        }
    },
    async created() {
        const keys = Object.keys(this.hasEval);
        const hasRes = keys.map((type) => this.registration.getHasResult(this.currReg.id, type));

        const res = await Promise.all(hasRes);

        const mappedRes = keys.map((key, idx) => ({
            [key]: res[idx]
        }))

        this.hasEval = Object.assign({}, ...mappedRes);
    },
    methods: {
        castValue(toCast: number|boolean): number {
            // eslint-disable-next-line no-nested-ternary
            return (typeof toCast === 'boolean') ? (toCast ? 1 : -1) : toCast
        },

        getIcon(val: number|boolean) {
            return this.castValue(val) === 1 ? 'mdi-check-circle-outline' : '';
        },

        getColor(val: number|boolean) {
            if (this.isNoShow) {
                return 'grey';
            }

            switch (this.castValue(val)) {
                case -1:
                    return 'grey'
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    return 'grey'
            }
        }
    },
})
</script>

<template>
    <v-radio
        v-model="showChecked"
        color="info"
        :disabled="true"
    >
        <template #label>
            {{ selectedName }}
        </template>
    </v-radio>
</template>

<script lang="ts">
import { useEventStore, useRegistrationsStore } from '@/stores/store';
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ERecived',
    props: {
        hideDetails: Boolean,
        selected: {
            type: Object,
            default: () => {}
        },
        input: {
            type: Object,
            default: () => {}
        },
        values: { 
            type: Array, 
            default: () => [] 
        },
    },
    emits: ['setScope'],
    data() {
        return {
            registrations: useRegistrationsStore(),
            events: useEventStore(),
            showChecked: false,
            selectedName: ''
        }
    },
    mounted() {
        switch (this.input.scope) {
            case 'terrang':
            case 'test_type': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                const eventType = event.categories.find((v) => v.type === 'Provform')?.value;
                const eventValue = this.input.value.find((v: any) => v.name === eventType);
                
                if (!eventValue) {
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                this.showChecked = true;
                break;
            }
            case 'provslag': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                const eventType = event.categories.find((v) => v.type === 'Provslag')?.value;
                const eventValue = this.input.value.find((v: any) => v.value === eventType?.toLowerCase());

                if (!eventValue) {
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                this.showChecked = true;
                break;
            }
            case 'provtyp': {
                const event = this.events.getEventByID(this.$route.params.eventId.toString());
                const eventType = event.categories.find((v) => v.type === 'Provtyp')?.value;
                const eventValue = this.input.value.find((v: any) => v.name === eventType);

                if (!eventValue) {
                    return;
                }

                this.$emit('setScope', this.input.scope, eventValue.value);
                this.selectedName = eventValue.name;
                this.showChecked = true;
                break;
            }
            case 'class': {
                const currReg = this.registrations.getRegistrationByID(this.$route.params.regId as string);
                const regValue = this.input.value.find((v: any) => v.name === currReg.class);
                
                if (!regValue) {
                    return;
                }

                this.$emit('setScope', this.input.scope, regValue.value);
                this.selectedName = regValue.name;
                this.showChecked = true;
                break;
            }
            default:
                break;
        }
    }
})

</script>
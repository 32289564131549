import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createBlock as _createBlock, vShow as _vShow, withDirectives as _withDirectives, normalizeClass as _normalizeClass, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "page-title d-flex justify-center mb-4" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "mb-8" }
const _hoisted_5 = { class: "d-flex justify-end mt-n4 text-subtitle-2" }
const _hoisted_6 = {
  key: 0,
  class: "mr-auto"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item_subtitle = _resolveComponent("v-list-item-subtitle")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_Transition, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_container, {
        class: _normalizeClass({'green-bg': _ctx.kkInstance})
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { cols: "12" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                (_ctx.kkInstance)
                  ? (_openBlock(), _createElementBlock("h1", _hoisted_2, " Välj besök "))
                  : (_openBlock(), _createElementBlock("h1", _hoisted_3, " Välj registrering "))
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_v_text_field, {
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: "Sök",
                  variant: "solo",
                  "append-inner-icon": "mdi-magnify",
                  class: "rounded-md"
                }, null, 8, ["modelValue"]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.search !== '')
                    ? (_openBlock(), _createElementBlock("strong", _hoisted_6, " Sökträffar: " + _toDisplayString(_ctx.filteredRegistrations.length), 1))
                    : _createCommentVNode("", true),
                  _createElementVNode("strong", null, [
                    _createTextVNode(" Registrerade "),
                    (_ctx.kkInstance)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_7, " besök: "))
                      : (_openBlock(), _createElementBlock("span", _hoisted_8, "hundar:")),
                    _createTextVNode(" " + _toDisplayString(_ctx.registrations.registrations.length), 1)
                  ])
                ])
              ]),
              _createVNode(_component_v_list, {
                class: "pa-0",
                style: {"background":"none"}
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredRegistrations, (reg) => {
                    return _withDirectives((_openBlock(), _createBlock(_component_v_list_item, {
                      key: reg.id,
                      elevation: "2",
                      class: "rounded-md mb-2 px-4 py-2 bg-white",
                      onClick: ($event: any) => (_ctx.$router.push({
                            name: 'RegistrationView',
                            params: {
                                'regId': reg.id,
                            }
                        }))
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_avatar, {
                          color: _ctx.getAvatarColor(reg)
                        }, {
                          default: _withCtx(() => [
                            (reg.start_order != null)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(reg.start_order + 1), 1))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1032, ["color"])
                      ]),
                      append: _withCtx(() => [
                        _createVNode(_component_v_icon, { color: "info" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" mdi-arrow-right ")
                          ]),
                          _: 1
                        })
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, { class: "text-subtitle-1" }, {
                          default: _withCtx(() => [
                            _createElementVNode("strong", null, _toDisplayString(reg.title ?? ''), 1)
                          ]),
                          _: 2
                        }, 1024),
                        (reg.class)
                          ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                              key: 0,
                              class: "text-subtitle-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Klass: " + _toDisplayString(reg.class), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        (reg.breed)
                          ? (_openBlock(), _createBlock(_component_v_list_item_subtitle, {
                              key: 1,
                              class: "text-subtitle-2"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(" Ras: " + _toDisplayString(Array.isArray(reg.breed) ? reg.kennel.breed.join(', ') : reg.breed), 1)
                              ]),
                              _: 2
                            }, 1024))
                          : _createCommentVNode("", true),
                        _createVNode(_component_v_list_item_subtitle, { class: "text-subtitle-2" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(reg.ts ? _ctx.registrationDate(reg) : ''), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])), [
                      [_vShow, reg.visible]
                    ])
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _: 1
  }))
}